import React, { useEffect, useRef, useContext, useState } from 'react';
import { gsap } from 'gsap';
import { SpInContextBridged } from '../../../Context';
import { WindowSizeContextBridged } from '../../../WindowSizeContext';
import { useAdjustNavOffset } from '../../../hooks/useAdjustOffsets';
import './pricing.scss';
import { DownCarrot } from '../../../svg/DownCarrot';
import { UpCarrot } from '../../../svg/UpCarrot';

export const Pricing = ({ section }) => {
	const [activeCardIndex, setActiveCardIndex] = useState(0);
	const { timelines, setDeltaY, currentSection, startHome, jumpTo } = useContext(SpInContextBridged);
	const [startX, setStartX] = useState(null); //for swiping through cards
	const [startY, setStartY] = useState(null); //Y buffer
	timelines[section] = useRef();
	const pricingRef = useRef(null);
	const cardLeft = useRef();
	const cardMid = useRef();
	const cardRight = useRef();
	const title = useRef();
	const cardsContainerEl = useRef(null);

	const [isSwapping, setIsSwapping] = useState(false);

	useEffect(() => {
		if (cardLeft.current) {
			cardLeft.myCustomRef = cardLeft;
		}

		const staggerSpeed = 0.3;
		const easing = "power2.inOut";

		timelines[section].current = gsap.timeline({ paused: true })
			.add('start')
			.to(pricingRef.current, { autoAlpha: 1, ease: easing, duration: 0.5 })
			.add('move-cards')
			.to([cardLeft.current, cardRight.current, cardMid.current], {
				display: 'inherit',
				stagger: staggerSpeed,
				ease: easing,
			}, 'move-cards')
			.to(title.current, { opacity: 1, ease: easing, duration: 0.5 })
			.add('middle')
			.to(title.current, { opacity: 0, ease: easing, duration: 0.5 }, 'middle')
			.to([cardLeft.current, cardRight.current, cardMid.current], {
				autoAlpha: 0,
				stagger: -staggerSpeed,
				ease: easing,
				duration: 0.5
			}, 'middle')
			.to(pricingRef.current, { autoAlpha: 0, ease: easing, duration: 0.5, delay: 0.5 }, 'middle')
			.add('end');

		cardsContainerEl.current = document.querySelector(".cards__wrapper");
	}, []);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (isSwapping) {
				return;
			}

			let cardElement;
			if (event.key === 'ArrowRight') {
				cardElement = cardsContainerEl.current.querySelector('.next--card');
			} else if (event.key === 'ArrowLeft') {
				cardElement = cardsContainerEl.current.querySelector('.previous--card');
			} else {
				return;
			}

			setIsSwapping(true);  // Set the isSwapping flag
			swapCards({ current: cardElement });

			setTimeout(() => {
				setIsSwapping(false);  // Reset the isSwapping flag
			}, 750);
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isSwapping]);


	useEffect(() => {
		const handleTouchStart = (e) => {
			setStartX(e.touches[0].clientX);
			setStartY(e.touches[0].clientY);
		};

		const handleTouchEnd = (e) => {
			const endX = e.changedTouches[0].clientX;
			const endY = e.changedTouches[0].clientY;
			const diffX = endX - startX;
			const diffY = endY - startY;

			if (isSwapping) {
				return;
			}
			// Add a buffer for y-axis drag (e.g., 30 pixels)
			if (Math.abs(diffY) > 80) {
				return;
			}

			let cardElement;

			if (diffX > 50) {
				cardElement = cardsContainerEl.current.querySelector('.previous--card');
			} else if (diffX < -50) {
				cardElement = cardsContainerEl.current.querySelector('.next--card');
			} else {
				return;
			}

			setIsSwapping(true);  // Set the isSwapping flag
			swapCards({ current: cardElement });

			setTimeout(() => {
				setIsSwapping(false);  // Reset the isSwapping flag
			}, 500);
		};

		cardsContainerEl.current.addEventListener('touchstart', handleTouchStart);
		cardsContainerEl.current.addEventListener('touchend', handleTouchEnd);

		return () => {
			cardsContainerEl.current.removeEventListener('touchstart', handleTouchStart);
			cardsContainerEl.current.removeEventListener('touchend', handleTouchEnd);
		};
	}, [startX, startY, isSwapping]);


	const handleCardClick = (event) => {
		if (isSwapping) {
			return;
		}
		let cardElement = event.currentTarget;
		setIsSwapping(true);
		swapCards({ current: cardElement });
		setTimeout(() => {
			setIsSwapping(false);
		}, 750);
	};

	function swapCardsClass(current, previous, next, direction) {
		const isRight = direction === 'right';
		const zIndexMap = isRight ? ['20', '50'] : ['50', '20'];

		[current, previous, next].forEach((el) => el.classList.remove("current--card", "previous--card", "next--card"));

		current.style.zIndex = '30';
		previous.style.zIndex = zIndexMap[0];
		next.style.zIndex = zIndexMap[1];

		current.classList.add(isRight ? 'previous--card' : 'next--card');
		previous.classList.add(isRight ? 'next--card' : 'current--card');
		next.classList.add(isRight ? 'current--card' : 'previous--card');
	}

	function swapCards(card) {
		if (card.current.classList.contains('current--card')) {
			return;
		}

		const [current, previous, next] = ['current--card', 'previous--card', 'next--card']
			.map(cls => cardsContainerEl.current.querySelector(`.${cls}`));

		let direction;
		const updateIndex = (val) => (val + 3) % 3;

		if (card.current.classList.contains('next--card')) {
			direction = 'right';
			setActiveCardIndex((prev) => updateIndex(prev + 1));
		} else if (card.current.classList.contains('previous--card')) {
			direction = 'left';
			setActiveCardIndex((prev) => updateIndex(prev - 1));
		}
		swapCardsClass(current, previous, next, direction);
	}

	return (
		<section ref={pricingRef} className="section pricing" onLoad={useAdjustNavOffset(pricingRef)}>
			<div className="cardList">

				<div className="cards__wrapper">
					<div ref={title} className={'title pricing-title section-header'}>
						<h1 style={{ fontWeight: 100, zIndex: 9999999, position: 'relative', margin: 0 }}>PRICING</h1>
					</div>
					<div className={'buffer'} />
					<div ref={cardMid} className="card current--card" onClick={(e) => handleCardClick(e)}>
						<PricingCard
							title='Standard'
							summary='Integrate 3D elements into most Front-end Frameworks'
							features={['Multiple 3D models', 'Basic Interactivity (Rotation, Zoom)', 'Standard Textures & Lighting', 'Desktop & Mobile Compatibility']}
							priceHeader='Starting at'
							price='$5,000'
							delivery='3-5 weeks'
						/>
					</div>

					<div ref={cardRight} className="card next--card" onClick={(e) => handleCardClick(e)}>
						<PricingCard
							title='Custom'
							summary='Immersive 3D Experience'
							features={['Fully Custom 3D Website', 'Advanced texture, Lighting & Shaders', 'Integrated Animations & Transitions', 'Mobile, Desktop, AR/VR (Optional)']}
							priceHeader='Pricing'
							price='By Project'
							delivery='TBD'
						/>
					</div>

					<div ref={cardLeft} className="card previous--card" onClick={(e) => handleCardClick(e)}>
						<PricingCard
							title='Stand Alone'
							summary='3D Viewer'
							features={['Single 3D Model', 'Dedicated Sub-Domain', 'Standard Textures & Lighting', '360 Degree Viewing', 'Mobile, Desktop Compatability']}
							priceHeader='Starting at'
							price='$3,000'
							delivery='1-2 weeks'
						/>
					</div>
				</div>
			</div>
		</section>
	)
};


const PricingCard = ({ title, summary, features, price, delivery, priceHeader }) => {
	const { device } = useContext(WindowSizeContextBridged);
	const { jumpTo } = useContext(SpInContextBridged);
	const contentRef = useRef(null);
	const cardRef = useRef(null);
	const cardOuterRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [currentPageIndex, setCurrentPageIndex] = useState(0); // New state variable
	const viewportHeight = useRef();
	const pages = useRef([])

	return (
		<div className="card__image" ref={cardOuterRef}>
			<div className="pricing-card" ref={cardRef}>
				<div className="type text-shadow">{title}</div>

				<div className="tag-line">
					<p className='text-shadow'>{summary}</p>
				</div>

				<div className="content" ref={contentRef} style={{ overflow: 'hidden' }}>
					<div className='page'>
						<div className='page-inner'>
							<div className="features-container">
								<div className="tag sub-page text-shadow">Features</div>
								<ul>
									{features.map((feature, index) => (
										<li key={index} className="sub-page text-shadow">{feature}</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="learn-more" onClick={() => jumpTo(4)}>
					<div>
						Get a Quote
					</div>
				</div>

				<div className="page">
					<div className='page-inner'>
						<div className="timeline sub-page">
							<p>Est. Delivery: {delivery}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export const Icon = () => (
	<div>
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
			width="100" height="100" viewBox="0 0 1986.000000 2757.000000"
			preserveAspectRatio="xMidYMid meet">
			<animate
				attributeName="stroke-opacity"
				values="1;0;1"
				dur="2s"
				repeatCount="indefinite" />
			<g transform="translate(0.000000,2757.000000) scale(0.100000,-0.100000)"
				fill="#ffffff" stroke="white" strokeWidth="300">
				<path d="M7120 26400 c-1526 -643 -3039 -1280 -3362 -1415 -446 -188 -586
-251 -586 -264 1 -9 -711 -1455 -1581 -3214 -871 -1759 -1581 -3200 -1580
-3201 2 -2 13 -8 24 -14 19 -11 18 -11 -7 -12 l-28 0 0 -5145 0 -5145 23 -1
c16 -1 18 -2 5 -6 -10 -2 -18 -11 -18 -19 0 -11 2081 -6871 2151 -7091 9 -29
14 -32 35 -27 23 6 25 4 22 -21 -3 -26 3 -29 947 -421 523 -216 955 -394 961
-394 6 0 16 10 22 23 l11 22 7 -22 c3 -13 11 -23 17 -23 14 0 1899 780 1911
791 6 5 5 18 -2 34 -11 24 -10 25 18 25 l30 0 0 6250 0 6250 -30 0 c-28 0 -29
1 -18 26 9 20 9 27 -1 33 -7 5 -431 181 -942 392 l-929 384 0 1147 0 1147 33
-14 c17 -7 833 -345 1812 -750 979 -405 1801 -746 1828 -757 l47 -20 0 -4084
0 -4084 30 0 c34 0 33 0 21 -33 -10 -24 -5 -26 952 -422 528 -219 962 -394
964 -388 2 6 10 19 18 29 13 17 14 17 15 2 0 -9 5 -20 10 -23 6 -4 442 172
969 391 952 394 959 397 950 421 -12 33 -13 33 21 33 l30 0 0 4068 1 4067
1859 781 1860 781 0 -1141 -1 -1141 -927 -383 c-511 -211 -935 -387 -942 -392
-11 -7 -12 -14 -2 -34 11 -25 10 -26 -18 -26 l-30 0 0 -6250 0 -6250 25 0 c22
0 25 -4 23 -31 l-3 -31 950 -394 c523 -216 955 -394 961 -394 7 0 15 10 18 23
l7 22 11 -22 c6 -13 15 -23 20 -23 12 0 1900 781 1911 790 4 4 3 19 -2 33 -10
25 -9 26 16 16 14 -5 27 -8 29 -6 6 6 2164 7108 2164 7122 0 8 -8 16 -17 18
-14 4 -12 5 5 6 l22 1 0 5145 0 5145 -27 0 c-26 1 -27 1 -8 12 11 6 22 12 24
14 2 1 -719 1445 -1602 3208 -883 1762 -1603 3206 -1601 3208 4 5 -6705 2828
-6732 2833 -11 2 -1268 -523 -2794 -1165z m2748 -8764 l-3 -3266 -3268 5135
c-1797 2825 -3270 5141 -3273 5148 -3 8 1083 470 3268 1388 l3273 1377 3
-3258 c1 -1792 1 -4728 0 -6524z m3332 8416 c1752 -737 3211 -1350 3242 -1364
l58 -24 -3250 -5134 c-1787 -2823 -3252 -5135 -3255 -5138 -3 -3 -5 2923 -5
6502 0 5203 3 6507 13 6503 6 -3 1445 -608 3197 -1345z m5131 -4975 l1409
-2817 -2 -5133 -3 -5132 -1077 -3534 c-592 -1943 -1080 -3537 -1085 -3542 -11
-11 -1790 -749 -1804 -749 -5 0 -9 2569 -9 6179 l0 6179 268 111 c147 61 571
236 942 390 598 248 675 282 673 300 -2 16 3 21 22 21 l25 0 0 2020 0 2020
-30 0 c-24 0 -33 6 -42 29 l-13 30 -680 -286 c-374 -158 -1672 -704 -2885
-1214 -1213 -510 -2204 -932 -2202 -938 1 -6 -9 -11 -23 -11 l-25 0 -2 -4085
-3 -4086 -880 -365 c-484 -200 -888 -367 -897 -370 -17 -5 -18 191 -18 4021 0
3031 3 4032 12 4048 6 12 1477 2337 3268 5167 1791 2830 3269 5165 3284 5189
l28 44 170 -334 c93 -184 804 -1602 1579 -3152z m-11767 -1749 l3306 -5197 0
-4026 c0 -3889 -1 -4026 -18 -4021 -10 4 -414 170 -898 370 l-879 365 -3 4085
-2 4086 -31 0 c-21 0 -29 4 -26 13 3 6 8 19 11 27 5 12 -569 254 -2881 1212
l-2888 1196 -13 -29 c-9 -23 -18 -29 -42 -29 l-30 0 0 -2020 0 -2020 25 0 c19
0 24 -5 22 -21 -2 -18 98 -62 933 -408 l935 -388 3 -6182 c2 -5886 1 -6183
-15 -6177 -20 8 -1787 738 -1790 740 -1 1 -487 1595 -1080 3541 l-1078 3540
-3 5134 -2 5135 1557 3148 c1169 2361 1561 3144 1569 3135 6 -7 1499 -2351
3318 -5209z m-3257 -2460 l782 -323 1 -1175 0 -1175 -762 -315 c-419 -173
-824 -341 -900 -372 l-138 -57 0 1919 0 1919 118 -49 c64 -27 469 -194 899
-372z m14263 -1492 l0 -1915 -107 44 c-60 25 -466 193 -903 374 l-795 329 -3
1165 -2 1165 97 41 c54 22 456 191 893 375 437 185 801 335 808 336 9 0 12
-389 12 -1914z m-997 -1637 c480 -198 875 -364 879 -367 3 -4 -388 -170 -871
-370 l-877 -363 -877 363 c-664 275 -874 365 -862 373 10 7 1671 700 1733 724
2 1 395 -161 875 -360z m-11503 -29 c454 -188 828 -344 832 -348 4 -4 -388
-170 -870 -370 l-877 -363 -877 363 c-482 200 -874 366 -870 370 4 3 398 168
876 366 l870 360 45 -18 c26 -10 417 -172 871 -360z m9675 -808 l890 -369 3
-6182 c2 -5885 1 -6182 -15 -6177 -10 3 -414 170 -898 371 l-880 364 -3 6181
c-1 3399 2 6180 6 6180 4 0 408 -166 897 -368z m-8725 -5821 l0 -6179 -47 -20
c-128 -55 -1743 -722 -1748 -722 -3 0 -4 2782 -3 6182 l3 6181 885 368 c487
202 891 368 898 368 9 1 12 -1241 12 -6178z"/>
			</g>
		</svg>
	</div>
);
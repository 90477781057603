import { OrbitControls } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import World from './World/World'

export function SpartanInExperience(props) {

	return <>
		{/* <Perf position="top-right" /> */}
		{/* <OrbitControls /> */}
		<World cameraRef={props.cameraRef} />
	</>
}

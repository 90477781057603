import React, { useContext, useEffect, useRef } from 'react';
import { SpInContextBridged, SpInContext } from '../../../Context';
import { examples } from './examples.const.ts';
import gsap from 'gsap';
import './examples.scss';
import '../sections.scss';
import { useScrollHandler } from '../../../hooks/useScrollHandler';
import { useAdjustNavOffset } from '../../../hooks/useAdjustOffsets';

export const Examples = ({ section }) => {
	const { lastSection, currentSection, setAllowScrolling, timelines, setIgnoreTouchEvent, ignoreWheelEvent, setIgnoreWheelEvent, device } = useContext(SpInContextBridged);
	const ref = useRef();
	const tickBox = useRef();
	const tickBoxContent = useRef();
	const headerRef = useRef();
	const { handleWheel, handleDragStart, handleDragEnd } = useScrollHandler(tickBoxContent);
	timelines[section] = useRef()

	// useEffect(() => { //TODO - probably should be moved somewhere else
	// 	if (currentSection == 'portfolio') {
	// 		tickBoxContent.current.scrollTop = 0;
	// 	}
	// }, [currentSection])

	// New useEffect to handle fading of h1 based on scroll
	useEffect(() => {
		const handleFade = () => {
			const scrollTop = tickBoxContent.current.scrollTop;
			if (scrollTop > 50) {
				gsap.to(headerRef.current, { duration: 0.3, autoAlpha: 0 });
			} else {
				gsap.to(headerRef.current, { duration: 0.3, autoAlpha: 1 });
			}
		};
		tickBoxContent.current.addEventListener('scroll', handleFade);
		return () => {
			if (tickBoxContent.current) {
				tickBoxContent.current.removeEventListener('scroll', handleFade);
			}
		}
	}, []);


	useEffect(() => {
		timelines[section].current = gsap.timeline().pause()
			.add('start')
			.set(tickBox.current, { width: '100%' })
			.set(tickBox.current, { height: '100%' })
			.set(tickBoxContent.current, { display: 'inherit' })
			.to(ref.current, { delay: 0.25, duration: 2, autoAlpha: 1, onComplete: () => { setAllowScrolling(true) } })
			.add('middle')
			.to(tickBox.current, { duration: 0.5, height: 0 }, 'examples-out')
			.set(tickBoxContent.current, { display: 'none' })
			.set(tickBox.current, { width: 0 })
			.to(ref.current, { duration: 0.25, autoAlpha: 0 })
			.add('end');
		gsap.to(headerRef.current, { duration: 0.5, autoAlpha: 1 })
	}, [])

	return (
		<section ref={ref} className={"section examples"} style={{ display: 'flex', position: 'relative', height: '-webkit-fill-available', width: '-webkit-fill-available', opacity: 0, zIndex: 10, padding: 0 }} >
			<div className={'examples-card'} >
				<div ref={tickBox} className='card-body body-hidden-over'>
					<div ref={tickBoxContent} style={{ display: 'none', overflowX: 'hidden' }} className='body'
						onScroll={handleWheel}
						onTouchStart={handleDragStart}
						onTouchEnd={handleDragEnd}
					>
						<div ref={headerRef} className={'section-header title'} style={{ opacity: 1, display: 'inherit', color: 'white' }} >
							<h1>PORTFOLIO</h1>
						</div>
						<div
							className='grid-wrapper'>
							{examples.map((ex, index) => (
								<ExampleCard key={index} example={ex} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

const ExampleCard = React.forwardRef(({ example }, ref) => {
	const { spInNavigator, setIncludeMouseTracer } = useContext(SpInContextBridged);

	const overlayRef = useRef();
	const overlayRefAnimation = useRef();

	useEffect(() => {
		overlayRefAnimation.current = gsap.timeline({ paused: true })
			.fromTo(overlayRef.current,
				{
					opacity: 0
				},
				{
					opacity: 1,
					duration: 0.2,
				}
			);
	}, [])

	const reroute = (path) => {
		//get body and fade to black
		const body = document.querySelector('.loading-wrapper');
		gsap.to(body, { duration: 1, autoAlpha: 1 })
			.then(() => {
				spInNavigator(path)
			})
	}


	return (
		<>
			<div ref={ref} className='grid-item' style={{ opacity: 1 }} onClick={() => { example.isExt ? window.open(example.route, '_blank') : reroute(example.route) }}>
				<div className='ex-card'>
					<img className='image-item' src={example.image}></img>
					<div className='description-box'>
						<div className='examples-title' style={{ color: example.title === "NCMS" ? "black" : "white" }}>
							{example.title}
						</div>
						<div className='description' style={{ color: example.title === "NCMS" ? "black" : "white" }} >
							{example.description}
						</div>
					</div>
				</div>
				<div className="overlay" ref={overlayRef} style={{ opacity: 0 }}
					onMouseEnter={() => {
						overlayRefAnimation.current.play()
					}}
					onMouseLeave={() => {
						overlayRefAnimation.current.reverse()
					}}
				>
					<div className='overlay-icon'>
						{
							example.isExt
								? <ExternalHover />
								: <InternalHover />
						}
						{
							example.isExt
								? <p>Visit External Site</p>
								: <p>View Example</p>
						}
					</div>
				</div>
			</div>
		</>
	)
})

const ExternalHover = () => {
	return <img className='ex-icon' src='/assets/svg/ext-link.svg' />
}

const InternalHover = () => {
	return <img className='ex-icon' src='/assets/svg/cube.svg' />
}

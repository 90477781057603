import React, { useEffect, useRef, useContext, useState, useLayoutEffect } from 'react';
import './../sections.scss';
import './faq.scss';
import { SpInContextBridged } from '../../../Context';
import { WindowSizeContextBridged } from '../../../WindowSizeContext';
import gsap from 'gsap';
import { UpCarrot } from '../../../svg/UpCarrot';
import { DownCarrot } from '../../../svg/DownCarrot';
import { useScrollHandler } from '../../../hooks/useScrollHandler';

export const Faq = ({ section }) => {
	const { device } = useContext(WindowSizeContextBridged);
	const { currentSection, setAllowScrolling, timelines, maxY, numSections, setDeltaY } = useContext(SpInContextBridged);
	timelines[section] = useRef();

	const refArrays = useRef(Array.from({ length: 5 }, () => ({
		faqListAnimation: useRef(null),
		faqList: useRef(null),
		listHeader: useRef(null),
	}))).current;

	const faqTimelines = useRef([]);
	const bodyContentRef = useRef();
	const title = useRef();
	const faqRef = useRef()
	const sectionInnerRef = useRef();

	const [activeIndexes, setActiveIndexes] = useState({});
	const { handleWheel, handleDragStart, handleDragEnd } = useScrollHandler(bodyContentRef);

	const toggleAnswer = (index) => {
		setActiveIndexes(prevState => {
			const newState = { ...prevState, [index]: !prevState[index] };
			newState[index] ? faqTimelines.current[index].play() : faqTimelines.current[index].reverse();
			return newState;
		});
	};

	useEffect(() => { // Close all FAQ items when section changes
		if (currentSection !== section) {
			const newActiveIndexes = Object.keys(activeIndexes).reduce((acc, key) => {
				acc[key] = false;
				return acc;
			}, {});
			setActiveIndexes(newActiveIndexes);
			faqTimelines.current.forEach((tl) => tl.reverse());
		}
	}, [currentSection]);

	useLayoutEffect(() => {
		refArrays.forEach((refs, index) => {
			if (refs.faqList.current) { // Check if ref is null
				const tl = gsap.timeline({
					paused: true,
				})
					.set(refs.faqList.current, { height: '0', visibility: 'hidden' })
					.to(refs.faqList.current,
						{
							height: 'auto',
							autoAlpha: 1,
							duration: 0.25,
							ease: 'power2.out',
						});
				faqTimelines.current[index] = tl; // Store the timeline
			}
		});

		refArrays.forEach((refs, index) => {
			if (refs.faqList.current) { // Check if ref is null
				refs.faqListAnimation.current = gsap.timeline()
					.to(refs.faqList.current, {
						onStart: () => {
							refs.faqList.current.classList.toggle('active');
						},
						height: 'auto',
						duration: 0.35,
						ease: 'power4.InOut',
					})
					.pause();
			}
		});

		setAllowScrolling(true);
	}, []);

	useEffect(() => {
		if (!timelines[section]) {
			timelines[section] = {};
		}

		timelines[section].current = gsap.timeline({ pause: true }) // use { paused: true } for clarity
			.add('start')
			.to(faqRef.current,
				{
					autoAlpha: 1, duration: 0.5
				}
			)
			.to(bodyContentRef.current,
				{
					autoAlpha: 1,
					duration: 0.5,
					ease: 'power2.Out',
				}
			)
			.add('middle')
			// .to(bodyContentRef.current,
			// 	{
			// 		autoAlpha: 0,
			// 		duration: 0.5,
			// 		ease: 'power2.Out',
			// 	}
			// )
			// .to(faqRef.current,
			// 	{ autoAlpha: 0, duration: 0.5 }
			// )
			.add('end');
	}, []);

	const faqItems = [
		// { question: 'What is 3D web design?', answers: ['3D web design incorporates interactive 3D elements to offer an immersive user experience.'] },
		{ question: 'What are the benefits of 3D web design?', answers: ["3D elements can enhance a website's aesthic appeal, strengthen a brand's unique identity, and increase user engagement."] },
		{ question: 'Can I incorporate 3D web design into my existing website?', answers: ['In most cases 3D elements can be added to an existing website without requiring a complete overhaul.'] },
		{ question: 'Is 3D web design compatible with all browsers and devices?', answers: ['Yes, 3D web design is compatible with most modern browsers and devices. Performance may vary based on the device’s capabilities so we optimize our designs to ensure broad compatibility and smooth performance.'] },
		{ question: 'Where can I see examples of 3D web design?', answers: ['Examples of our work can be viewed on our portfolio page and you can additionally visit threejs.org to see examples from the community.'] },
		// { question: 'Is 3D web design mobile-friendly?', answers: ['Yes, our 3D web designs are created with a mobile-first approach, ensuring that they look great and perform well on mobile devices as well as on desktops.'] },
	];

	return (
		<section ref={faqRef} className={device === "small" ? "section faq faq-mobile" : "section faq"}>
			<div ref={sectionInnerRef} className="section-inner"
			>
				<div className="card-body" >
					<div className="card-body-content" ref={bodyContentRef}
						style={{ display: 'flex', flexDirection: 'column', overflow: 'scroll', scrollBehavior: 'smooth' }}>
						<div ref={title} className={'title team-title section-header'}>
							<h1 style={{ zIndex: 9999999, position: 'relative', marginLeft: '5%' }}>AboUT</h1>
						</div>
						<div className="about-description" style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem', boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.3)' }}>
							<p style={{ margin: 0 }}>	At <ins style={{ fontFamily: 'Squids', textDecoration: 'none' }}>SPARTAN INNOVATIONz</ins>, we revolutionize online experiences through stunning 3D web designs. Specializing in creating immersive websites, we seamlessly integrate interactive 3D elements to make your brand unforgettable.
							</p>
						</div>
						<ul className="faq-list-main">
							{faqItems.map((item, index) => (
								<li className="faq-list-main-item" key={'faq_' + index} onClick={() => toggleAnswer(index)}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div className={'header-wrapper'} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
											<div style={{ display: 'flex' }}>
												<h2 ref={refArrays[index].listHeader} className="faq-list-main-header">
													{item.question}
												</h2>
												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
													{
														activeIndexes[index] ? <UpCarrot /> : <DownCarrot />
													}
												</div>
											</div>
											<ul ref={refArrays[index].faqList} className={`faq-list-sub ${activeIndexes[index] ? 'active' : ''}`}>
												{item.answers.map((answer, subIndex) => (
													<div key={'faq_sub_' + subIndex}>
														<li>
															<span >
																{answer}
															</span>
														</li>
													</div>
												))}
											</ul>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

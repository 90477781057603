// import { useGLTF, useTexture } from "@react-three/drei"
// import { useSnapshot } from "valtio"
// import { useFrame } from "@react-three/fiber"
// import { useLayoutEffect, useRef } from "react"
// import { Float } from "@react-three/drei"
// import gsap from "gsap"
// import * as THREE from "three"

// export default function Helmet() {

//     const maskModel = useGLTF('/assets/models/helmetMask.glb')
//     const maskRef = useRef()

//     useLayoutEffect(() => {
//         maskModel.scene.traverse((child) => {
//             if (child.name == 'Mask') {
//                 maskRef.current = child
//                 child.material = new THREE.MeshStandardMaterial({
//                     color: 'gray',
//                 })
//                 child.castShadow = true
//             }
//         })
//     }, [])

//     return <>
//         <primitive object={maskModel.scene} position={[0, -0.01, -1]} scale={1} />
//     </>


// }


import { useGLTF, useTexture } from "@react-three/drei";
import { useLayoutEffect, useRef } from "react";
import * as THREE from "three";

export default function Helmet() {

    const maskModel = useGLTF('/assets/models/helmetMask.glb');
    const maskRef = useRef();

    useLayoutEffect(() => {
        maskModel.scene.traverse((child) => {
            if (child.name === 'Mask') {
                maskRef.current = child
                child.material = new THREE.MeshStandardMaterial({
                    color: 'gray',
                })
                child.castShadow = true;
            }
        });
    }, []);

    return (
        <>
            <primitive object={maskModel.scene} position={[0, -0.01, -1]} scale={1} />
        </>
    );
}

import { useEffect } from 'react';

export const useAdjustNavFooterOffset = (componentRef) => {
    useEffect(() => {
        const updateNavbarOffset = () => {
            const navbar = document.querySelector('.content-wrapper-inner-border') ||
                document.querySelector('.nav-tick-container');
            const footer = document.querySelector('.footer') ? document.querySelector('footer') : 0;

            componentRef.current.style.marginTop = navbar ? `${navbar.offsetHeight}px` : '0px';
            componentRef.current.style.marginBottom = footer ? `${footer.offsetHeight ? footer.offsetHeight : 0}px` : '0px';
        };

        updateNavbarOffset();

        // Optionally: Re-run this function when the window resizes or other relevant events
        window.addEventListener('resize', updateNavbarOffset);
        return () => window.removeEventListener('resize', updateNavbarOffset);
    }, [componentRef]);
}


export const useAdjustNavOffset = (componentRef) => {
    useEffect(() => {
        const updateNavbarOffset = () => {
            const navbar = document.querySelector('.content-wrapper-inner-border') ||
                document.querySelector('.nav-tick-container');

            componentRef.current.style.marginTop = navbar ? `${navbar.offsetHeight}px` : '0px';
        };

        updateNavbarOffset();

        // Optionally: Re-run this function when the window resizes or other relevant events
        window.addEventListener('resize', updateNavbarOffset);
        return () => window.removeEventListener('resize', updateNavbarOffset);
    }, [componentRef]);
}

export const examples = [
	{
		isExt: false,
		image: '/assets/examples/satellite/satellite.jpeg',
		title: 'ISS Tracker',
		description: 'Created for the Columbia Memorial Space Center',
		route: '/portfolio/space-station'
	},
	{
		isExt: true,
		image: '/assets/examples/aSpartanDream/aSpartanDreamExampleImage.jpeg',
		title: 'A Spartan Dream',
		description: 'Created for the film "A Spartan Dream"',
		route: 'https://www.aspartandream.com'
	},
	{
		isExt: false,
		image: '/assets/examples/skateboard/skateboardImage.jpeg',
		title: 'Customizable Product',
		description: 'An example of a 3D product with configurable colors and materials',
		route: '/portfolio/skateboard'
	},
	{
		isExt: false,
		image: '/assets/examples/bike/bikeExampleImage.jpeg',
		title: 'Product Configurator',
		description: 'An example of a configurable product with highlighted features',
		route: '/portfolio/bike'
	},
	// {
	// 	isExt: false,
	// 	image: '/assets/examples/skateboard/skateboardExampleImage.JPG',
	// 	title: 'Customizable Product',
	// 	description: 'An example of a 3D product with configurable colors and materials',
	// 	route: '/portfolio/skateboard'
	// },

	// {
	// 	isExt: false,
	// 	image: '/assets/examples/portal/portal.png',
	// 	title: 'Portal',
	// 	description: 'An Interactive Exhibit showcasing multiple worlds',
	// 	route: '/portfolio/portal'
	// },
	{
		isExt: true,
		image: '/assets/examples/ncms/ncms.jpeg',
		title: 'NCMS',
		description: 'Created for National Cash Management Systems',
		route: 'https://www.ncms-inc.com'
	}
];

import { useState, useEffect, useRef, useContext } from "react";
import { SpInContextBridged } from "../../../Context";
import './../sections.scss';
import './home.scss';
import gsap from "gsap";
import { WindowSizeContextBridged } from "../../../WindowSizeContext";
import { SwipeDownLottie } from "../../SectionIndicator/SwipeDownLottie";
import { useAdjustNavOffset } from "../../../hooks/useAdjustOffsets";

export const Home = ({ section }) => {
	const { device } = useContext(WindowSizeContextBridged);
	const { lastSection, currentSection, timelines, setTimelines, setCameraPan, setAllowScrolling } = useContext(SpInContextBridged);

	const rawTextTop = "Spartan innovations";
	const textArrTmpTop = rawTextTop.split(' ');
	const textCharArrTop = [];

	for (let i = 0; i < textArrTmpTop.length; i++) {
		textCharArrTop.push(textArrTmpTop[i].split(''));
	}

	textCharArrTop[0].push(' ');

	const homeRef = useRef();
	const homeTitleRef = useRef();
	const homeSubTitleRef = useRef();
	const lottieRef = useRef();

	timelines[section] = useRef();

	function shuffleArray(array) {
		let currentIndex = array.length, randomIndex;
		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
		}
		return array;
	}

	useEffect(() => {
		// const homeTitleChars = shuffleArray(Array.from([...homeTitleRef.current.children[0].children, ...homeTitleRef.current.children[1].children]));

		timelines[section].current = gsap.timeline().pause()
			.add('start')
			// .to(homeTitleChars, {
			// 	autoAlpha: 1,
			// 	duration: 0.75,
			// 	stagger: 0.03,
			// 	ease: 'power2.in',
			// 	onComplete: (() => {
			// 		setAllowScrolling(true);
			// 	})
			// })
			.to(homeTitleRef.current, {
				autoAlpha: 1,
				duration: 0.75,
				ease: 'power2.in',
				onComplete: (() => {
					setAllowScrolling(true);
				})
			})
			//child of this
			.to(homeSubTitleRef.current.children, {
				autoAlpha: 1,
				duration: 0.8,
				stagger: 0.2,
				ease: 'power4.In',
				y: 0,
				onComplete: (() => {
					setCameraPan(true);
				})
			})
			.to(lottieRef.current, {
				autoAlpha: 1,
				duration: 0.8,
				ease: 'power1.out',
				y: 0,
				delay: 0.5
			}, "<")
			.add('middle')
			.to(lottieRef.current, {
				autoAlpha: 0,
				duration: 1,
				ease: 'power1.out',
				y: 0,
			})
			// .to(homeTitleChars, {
			// 	autoAlpha: 0,
			// 	duration: 0.75,
			// 	ease: 'power2.inOut'
			// })
			.to(homeTitleRef.current, {
				autoAlpha: 0,
				duration: 0.75,
				ease: 'power2.in',
			})
			.to(homeSubTitleRef.current, {
				autoAlpha: 0,
				duration: 0.75,
				delay: 0,
				y: 0,
				ease: 'power2.inOut',
			}, "<")
			.add('end')
	}, []);

	return (
		<section ref={homeRef} className={device == "small" ? "section home home-mobile" : "section home"}>
			<header className="header">
				<div className={"section-div-inner"}>
					<div className="title-wrapper">
						<div ref={homeTitleRef} style={{ opacity: 0 }} onLoad={useAdjustNavOffset(homeTitleRef)} className="title">
							{
								textCharArrTop.map((word, i) => <div key={`${i}`} className={"home title word"}>
									{word}

									{/* {
										textCharArrTop[i].map((char, j) => <span key={j} className={"home title word char"}>
											{
												char
											}
										</span>)
									} */}
								</div>)
							}

						</div>
					</div>
					<div className='spacer' />


					<h5 ref={homeSubTitleRef} className="sub-title" style={{ opacity: 1, textAlign: 'center' }}>
						<p style={{ fontSize: 'medium', opacity: 0 }}>3D Web Design</p>
						<p style={{ fontSize: 'large', opacity: 0 }}>Immersive Experiences</p>
					</h5>

					{
						device == "small" ?
							<div ref={lottieRef} style={{ opacity: 0, display: 'flex', justifyContent: "center", alignItems: 'end', height: 'auto' }}>
								<SwipeDownLottie />
							</div>
							: <></>
					}
				</div>

			</header>
		</section>
	);
}

import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react';
import './navigation-bar-mobile.scss';
import gsap from 'gsap';
import { SpInContext, numSections, maxY } from '../../../Context';
import { UpCarrot } from '../../../svg/UpCarrot';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingIcon } from '../../../svg/LoadingIcon';

export const NavigationBarMobile = () => {
	const { currentSection, setDeltaY } = useContext(SpInContext)
	const [isOpen, setIsOpen] = useState(false);
	const mobileNavDrawerRef = useRef();
	const mobileNavDrawerAnimation = useRef();

	const mobileNavListRef = useRef();
	const mobileNavRef = useRef();
	const mobileNavContactRef = useRef();
	const mobileNavControlsRef = useRef();
	const SpartanLogoRef = useRef();
	const navTickMiddleRef = useRef();
	const messageNotificationRef = useRef();


	const [formData, setFormData] = useState({
		email: "",
		message: "",
	});

	const [messageText, setMessageText] = useState('Sending...')
	const emailErrorMsg = "Please enter a valid email address"
	const [emailError, setEmailError] = useState(false);
	const [sending, setSending] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value })
	};

	const toggleDrawer = () => {
		setIsOpen(!isOpen);
	}

	useLayoutEffect(() => {
		mobileNavDrawerAnimation.current = gsap.timeline().to(
			mobileNavRef.current, {
			background: 'rgba(0,0,0,0.8)',
			opacity: 1,
			duration: 0.25,
			onReverseComplete: () => {
				mobileNavRef.current.style.pointerEvents = 'none';

			},
			onComplete: () => {
				mobileNavRef.current.style.pointerEvents = 'all';
			}
		})
			.to(mobileNavControlsRef.current, {
				background: 'whitesmoke',
				autoAlpha: 1,
				duration: 0.125,
				margin: '1rem',
				y: '-10px',
				ease: 'power4.InOut',
			}).to(SpartanLogoRef.current, {
				autoAlpha: 1
			}, '-=0.125').to(navTickMiddleRef.current, {
				width: '20px',
				power4: 'InOut',
				duration: 0.125,
			}, '-=0.5')
			.to(mobileNavListRef.current, {
				width: 'auto',
				height: 'auto',
				autoAlpha: 1,
				duration: 0.125, y: '-10px',
				ease: 'power4.Out',
			}, '-=0.125').to(mobileNavContactRef.current, {
				width: 'auto',
				height: 'auto',
				autoAlpha: 1,
				duration: 0.125, y: '-10px',
				ease: 'power4.Out',
			})

	}, [])

	useEffect(() => {
		if (isOpen) {
			mobileNavDrawerAnimation.current.play();
		} else {
			mobileNavDrawerAnimation.current.reverse();
		}
	}, [isOpen])

	const jumpTo = (index) => {
		const sectionDelta = maxY * (index / numSections);
		setDeltaY(sectionDelta, currentSection)
		mobileNavDrawerAnimation.current.reverse();
	}

	const validateEmail = (email) => {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(email).toLowerCase())
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!validateEmail(formData.email)) {
			setEmailError(true)
		} else {
			setEmailError(false);
			setSending(true);

			//clear forms
			setFormData({
				email: "",
				message: "",
			});

			gsap.to(messageNotificationRef.current, {
				height: 'auto',
				duration: 0.25,
				opacity: 1,
				ease: 'power4.InOut',
			})


			axios.post(
				'https://www.spartan-in.com/contact/send',
				JSON.stringify({ fromEmail: formData.email, message: formData.message, name: 'From Nav Menu (No Name)', subject: '' }),
				{
					headers: {
						"Access-Control-Allow-Origin": '*',
						"Access-Control-Allow-Methods": '*',
						"Content-Type": "application/json",
						"accept": "application/json",
					}
				}
			)
				.then((response) => {
					if (response) {
						setMessageText('Message received');
						setSending(false);
					}
				})
				.catch((error) => {
					setMessageText('Error Sending Email');
					setSending(false);
				})
		}
	};

	useEffect(() => {
		if (messageText == 'Message received' || messageText == 'Error Sending Email') {
			setTimeout(() => {
				gsap.to(messageNotificationRef.current, {
					height: 0,
					duration: 0.25,
					opacity: 0,
					ease: 'power4.InOut',
				})
			}, 2000)
		}
	}, [messageText]);

	return (
		<div>
			<nav className="navigation-bar-mobile" ref={mobileNavRef}>
				<div className="inner-wrapper" style={{ position: 'fixed', width: '100%' }}>
					<div className="mobile-nav-drawer" ref={mobileNavDrawerRef}>
						<div ref={mobileNavControlsRef} className="nav-controls" onClick={toggleDrawer} style={{ zIndex: 999 }}>
							<div ref={SpartanLogoRef} className="spartan-logo-wrapper">
								<div style={{ flexGrow: 1 }}>
									SPARTAN INNOVATIONz
								</div>
							</div>
							<div className={'nav-tick-container'}>
								<div className={'nav-tick'} />
								<div className={'nav-tick'} ref={navTickMiddleRef} style={{ justifySelf: 'end' }} />
								<div className={'nav-tick'} />
							</div>
						</div>

						{/* TODO */}
						<div className="mobile-nav-3d Element-canvas-thing" >

						</div>

						<div className="mobile-nav-list" ref={mobileNavListRef}>
							<ul onClick={() => setIsOpen(false)}>
								<li style={{ color: currentSection === 'home' ? 'red' : 'black' }} onClick={() => jumpTo(0)}>Home</li>
								<li style={{ color: currentSection === 'portfolio' ? 'red' : 'black' }} onClick={() => jumpTo(1)}>Portfolio</li>
								<li style={{ color: currentSection === 'about' ? 'red' : 'black' }} onClick={() => jumpTo(2)}>About</li>
								<li style={{ color: currentSection === 'pricing' ? 'red' : 'black' }} onClick={() => jumpTo(3)}>Pricing</li>
								<li style={{ color: currentSection === 'contact' ? 'red' : 'black' }} onClick={() => jumpTo(4)}>Contact</li>
							</ul>
						</div>

						<div className="mobile-nav-contact" ref={mobileNavContactRef}>
							<div className="mobile-nav-contact-inner">
								<form id="contactForm" style={{ padding: '0.75rem', paddingBottom: '0.5rem' }} autoComplete="off" onSubmit={handleSubmit}>
									<div style={{ paddingBottom: '0.5rem' }}>
										Have a Vision? Let's make it happen.
									</div>
									<div className="form-group">
										<textarea
											id="message"
											name="message"
											rows="2"
											placeholder='Message'
											value={formData.message}
											onChange={handleChange}
											required>
										</textarea>
									</div>
									<div className="button-wrapper">
										<div className="form-group" style={{ width: '100%' }}>
											<input
												type="email"
												id="email"
												name="email"
												value={formData.email}
												onChange={handleChange}
												placeholder='Email'
												required
											/>
										</div>

										<button type="submit"  >
											{
												sending ?
													<div className='submit-inner'>
														<LoadingIcon style={{ width: '100%' }} />
													</div>
													:
													<div style={{ transform: 'rotate(90deg)' }}>
														<UpCarrot />
													</div>
											}
										</button>
									</div>
									<div >
										<p ref={messageNotificationRef} style={{ display: 'flex', justifyContent: 'center', opacity: 0, margin: 0, height: 0, transition: 'all 0.25s ease-in-out' }}>
											{messageText}
										</p>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}
import Lights from './Lights'
import Helmet from './Helmet'
import Backdrop1 from './Backdrop1'
import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, useContext } from 'react'
import { SpInContext } from "../../../Context";
import { Sparkles } from '@react-three/drei'

export default function World(props) {

	const cameraGroupRef = useRef()
	const shadowRef = useRef()
	const { camera } = useThree()
	const { setStartHome } = useContext(SpInContext)

	useEffect(() => {
			setStartHome(true)
	}, [])

	useFrame((state,delta) => {	
		if(cameraGroupRef.current){
			cameraGroupRef.current.position.y = state.camera.position.y - 0.05
			cameraGroupRef.current.position.x = state.camera.position.x - 0
			cameraGroupRef.current.position.z = state.camera.position.z - 0.5
		}
	})

	return <>
		<group ref={cameraGroupRef}>
			<Lights/>
			<Backdrop1 />
			<Helmet />
			{/* <Sparkles speed={0.05} color={'white'}/> */}
		</group>

		{/* <Stars radius={100} depth={5} count={5000} factor={4} saturation={0} fade speed={1} position={[0,800,0]} /> */}
		{/* <Waves />  */}
		{/* <SkyBox /> */}
	</>
}
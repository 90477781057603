import React, { useEffect, useContext, useRef } from 'react';
import { SpInContext, minY, maxY, numSections } from '../../Context';

const touchEventWeight = 0.02;
const YDiffThreshold = 80; // Add this line to specify the YDiff threshold

export const Scroller2 = () => {
	const { deltaY, setDeltaY, currentSection, allowScrolling } = useContext(SpInContext);

	const totalRef = useRef(deltaY);
	const startTouchEventYRef = useRef(0);
	const currentSectionRef = useRef(currentSection);

	const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

	const wheelListener = (e) => {
		const newTotal = clamp(totalRef.current + e.deltaY, minY, maxY);
		totalRef.current = newTotal;

		setDeltaY(newTotal, currentSectionRef.current);
	};

	const calculateSwipe = (endY) => {
		const differenceTE = startTouchEventYRef.current - endY;

		// console.log(Math.abs(differenceTE), YDiffThreshold)

		if (Math.abs(differenceTE) > YDiffThreshold) {
			if (Math.abs(differenceTE) > window.innerHeight * touchEventWeight) {
				const nextSectionDelta = maxY * (1 / numSections);
				const newDelta = clamp(
					differenceTE > 0 ? totalRef.current + nextSectionDelta : totalRef.current - nextSectionDelta,
					minY,
					maxY
				);

				totalRef.current = newDelta;
				setDeltaY(newDelta, currentSectionRef.current);
			}
		}
	}

	useEffect(() => {
		totalRef.current = deltaY;
		currentSectionRef.current = currentSection;
	}, [currentSection]);

	useEffect(() => {
		window.addEventListener('wheel', wheelListener);
		window.addEventListener('touchstart', (e) => startTouchEventYRef.current = e.changedTouches[0].clientY);
		window.addEventListener('touchend', (e) => calculateSwipe(e.changedTouches[0].clientY));

		return () => {
			window.removeEventListener('wheel', wheelListener);
		};
	}, []);

	return null;
}

import React, { useState } from "react";

export const WindowSizeContext = React.createContext();
export const WindowSizeContextBridged = React.createContext();

export const WindowSizeContextProvider = ({ children }) => {
	const [windowSize, _setWindowSize] = useState();
	const [device, setDevice] = useState();

	const setWindowSize = (viewPort) => {
		if (viewPort.width < 767) {
			setDevice('small')
		} else if (viewPort.width >= 768 && viewPort.width < 1200) {
			setDevice('medium')
		} else if (viewPort.width > 1200) {
			setDevice('large')
		}

		_setWindowSize(viewPort);
	}

	return (
		<WindowSizeContext.Provider value={{
			windowSize, setWindowSize,
			device, setDevice
		}}>
			{children}
		</WindowSizeContext.Provider>
	);
};

export const WindowSizeContextProviderBridge = ({ value, children }) => {
	return (
		<WindowSizeContextBridged.Provider value={{ ...value }}>
			{children}
		</WindowSizeContextBridged.Provider>)
}

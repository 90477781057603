import React, { Suspense, useEffect, useRef } from 'react';
import { createBrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import { WindowSizeContextProvider } from './WindowSizeContext';
import { SpInContextProvider } from './Context';
import { App } from './App';
import gsap from 'gsap';
import './other.scss';
import { LoadingNoState } from './Components/Loading/Loading';
// import { ResourceManagerContextProvider } from './utils/ResourceManagerContext';
import { Carrot } from './svg/Carrot';
import { TileCoverNavi } from './conrad-test/TileCover';
import { ConradsCustomSuspense } from './ConradsCustomSuspense';

// const ModelExample = React.lazy(() => import('./Components/Examples/ModelExample/ModelExample'));
const BikeExample = React.lazy(() => import('./Components/Examples/BikeExample/BikeExample'));
const PortalExample = React.lazy(() => import('./Components/Examples/PortalExample/PortalExample'));
const SatelliteExample = React.lazy(() => import('./Components/Examples/SatelliteExample/SatelliteExample'));
const Skateboard = React.lazy(() => import('./Components/Examples/Skateboard/Skateboard'));

export const exampleRoutes = [
	{ path: '/portfolio/bike', component: <BikeExample /> },
	{ path: '/portfolio/space-station', component: <SatelliteExample /> },
	{ path: '/portfolio/skateboard', component: <Skateboard /> },
	// { path: '/portfolio/portal', component: <PortalExample /> },
];

const Navi = () => {
	const navBoi = useNavigate();
	const locBoi = useLocation();
	const leftDot = useRef();
	const leftBar = useRef();
	const rightBar = useRef();

	const leftAnim = useRef();
	const rightAnim = useRef();

	const navLeftRef = useRef();
	const navRightRef = useRef();
	const exitExampleRef = useRef();

	const overlayRef = useRef();

	useEffect(() => {
		gsap.fromTo([navLeftRef.current, navRightRef.current, exitExampleRef.current],
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				duration: 1,
			}
		)

		leftAnim.current = gsap.to('.left .dot svg polygon', {
			duration: 0.5,
			scale: 1.05,
			repeat: -1,
			x: 2,
			yoyo: true
		}).pause();

		rightAnim.current = gsap.to('.right .dot svg polygon', {
			duration: 0.5,
			scale: 1.05,
			repeat: -1,
			x: 2,
			yoyo: true
		}).pause();

		// console.log("now")
		// gsap.to(overlayRef.current, {
		// 	duration: 0,
		// 	autoAlpha: 1
		// })
	}, [])

	const navigate = (num) => {
		var index = exampleRoutes.findIndex((route) => route.path === locBoi.pathname);
		index = index + num;

		if (index < 0) {
			index = exampleRoutes.length - 1;
		}

		if (index > exampleRoutes.length - 1) {
			index = 0;
		}

		if (num < 0) {
			gsap
				.to(".black-screen-left", {
					duration: 1.5,
					width: '100%'
				}).then(() => {
					navBoi(exampleRoutes[index].path, { replace: true });
					gsap.set(".black-screen-left", { width: '0%' });
				})
		} else {
			gsap
				.to(".black-screen-right", {
					duration: 1.5,
					width: '100%',
					left: '0%'
				}).then(() => {
					navBoi(exampleRoutes[index].path, { replace: true });
					gsap.set(".black-screen-right", { width: '0%', left: '100%' });
				})
		}
	}



	function handleExit() {
		window.location.replace('/#portfolio')
	}

	return (
		<div ref={overlayRef}>
			<div ref={exitExampleRef} className={'exit-example'} onClick={handleExit}>
				<p>X</p>
			</div>

			<div ref={leftBar} className="black-screen-left"></div>

			<div ref={navLeftRef} className="left">
				<div ref={leftDot} className="dot" onClick={() => navigate(-1)}
					onMouseEnter={() => leftAnim.current.play()}
					onMouseLeave={() => leftAnim.current.pause()}
				>
					<Carrot />
				</div>
			</div >

			<div ref={navRightRef} className="right">
				<div className="dot" onClick={() => { navigate(1) }}
					onMouseEnter={() => rightAnim.current.play()}
					onMouseLeave={() => rightAnim.current.pause()}
				>
					<Carrot />
				</div>
			</div>

			<div ref={rightBar} className="black-screen-right"></div>
		</div>
	)
}

const ErrorRerouteHome = () => {
	const navBoi = useNavigate();

	useEffect(() => { navBoi('/', { replace: true }); }, [])

	return <></>
}

export const router = createBrowserRouter([
	{
		path: "/",
		element: (
			// <ResourceManagerContextProvider>
			<WindowSizeContextProvider>
				<SpInContextProvider>
					<App />
				</ SpInContextProvider>
			</WindowSizeContextProvider>
			// </ResourceManagerContextProvider>
		),
		errorElement: ErrorRerouteHome
	},
	{
		children: exampleRoutes.map((route) => ({
			path: route.path,
			element: (
				<div style={{ backgroundColor: 'transparent', height: '100%' }}>
					<ConradsCustomSuspense fallback={<LoadingNoState />}>
						<div style={{ backgroundColor: 'transparent', height: '100%' }}>
							<TileCoverNavi />
							<Suspense fallback={<></>}>
								{route.component}
							</Suspense>
						</div>
					</ConradsCustomSuspense>
				</div>
			)
		})),
		errorElement: ErrorRerouteHome
	},
	{
		path: '*',
		element: (
			// <ResourceManagerContextProvider>
			<WindowSizeContextProvider>
				<SpInContextProvider>
					<App />
				</SpInContextProvider>
			</WindowSizeContextProvider>
			// </ResourceManagerContextProvider>
		),
	}
]);

// export default App;

import { Canvas } from '@react-three/fiber'
import React, { useContext, useRef, useState, useEffect } from "react";
import { SpartanInExperience } from './SpartanInExperience/SpartanInExperience';
import { useContextBridge } from '@react-three/drei'
import { SpInContext } from '../Context';
import { WindowSizeContext } from '../WindowSizeContext';
import { Camera } from './Camera/Camera.jsx'
import { HtmlWithContext } from './HtmlWithContext';
import { Home } from './Sections/Home/Home';
import { Pricing } from './Sections/Pricing/Pricing';
import { Pricing2 } from './Sections/Pricing/Pricing2';
import { Faq } from './Sections/Faq/Faq.jsx';
import { Contact } from './Sections/Contact/Contact';
import { Examples } from './Sections/ExamplesGrid/Examples';
import BackgroundShader from './Camera/BackgroundShader';
import { Bloom, EffectComposer, DepthOfField, Noise, Vignette, LensFlare, GodRays, SMAA, ToneMapping } from '@react-three/postprocessing';
import * as THREE from 'three';
import { BlendFunction, KernelSize } from 'postprocessing';

const start = 'start';
const middle = 'middle';
const end = 'end';

export default function ThreeFiberCanvas() {
	const canvasRef = useRef();
	const cameraRef = useRef();
	const [dpr, setDpr] = useState(1.5);
	const ContextBridge = useContextBridge(SpInContext, WindowSizeContext);
	const { device } = useContext(WindowSizeContext);

	const context = useContext(SpInContext);

	useEffect(() => {
		if (context.currentSection === 'portfolio') {
			context.setShowExampleUI(true);
		}

		if (!!context.lastSection) {
			animationHandler(context.timelines[context.lastSection].current, false, context.lastSection);
		}

		if (!!context.timelines[context.currentSection]) {
			animationHandler(context.timelines[context.currentSection].current, true);
		}
	}, [context.lastSection, context.currentSection, context.loading]);

	// useEffect(() => {
	// 	// soft refresh - true then animation
	// 	// hard refresh - animation then true (broken)
	// 	if (context.startHome && context.currentSection === 'home' && context.timelines['home'].current.totalProgress() === 0) {
	// 		context.timelines['home'].current.play().tweenTo(middle);
	// 	}

	// }, [context.startHome]);

	const animationHandler = (timeline, isEntering, leaving) => {

		const progress = timeline.totalProgress();
		const atOrPastHalf = progress >= 0.5;

		if (isEntering) {
			//hard refresh = total progress of 0, is entering = true, leaving = undefined
			if (progress === 1 || !atOrPastHalf) {
				// if initial section isnt home, we still want to enable scrolling
				timeline.seek(0).play().tweenTo(middle).then(() => context.setAllowScrolling(true));
			} else {
				timeline.reverse().tweenTo(middle);
			}
		} else {
			if (atOrPastHalf) {
				timeline.play().tweenTo(end).then(() => {
					if (leaving === 'portfolio') {
						context.setShowExampleUI(false);
					}
				});
			} else {
				timeline.reverse().tweenTo(start).then(() => {
					if (leaving === 'portfolio') {
						context.setShowExampleUI(false);
					}
				});
			}
		}
	}

	return (
		<Canvas
			ref={canvasRef}
			color={0x000000}
			className="three-fiber-canvas"
			id="three-fiber-canvas"
			style={{ position: 'fixed', zIndex: 1, overflow: 'hidden' }}
			dpr={dpr}
			camera={{ fov: 55, position: [0, 800, 0] }}
			shadows={'soft'}
		>
			{/* <EffectComposer>
				<DepthOfField focusDistance={0} focalLength={0.1} bokehScale={1} height={480} />
				<ToneMapping
					// blendFunction={BlendFunction.NORMAL} // blend mode
					adaptive={true} // toggle adaptive luminance map usage
					resolution={256} // texture resolution of the luminance map
					middleGrey={0.9} // middle grey factor
					maxLuminance={16.0} // maximum luminance
					averageLuminance={1.0} // average luminance
					adaptationRate={1.0} // luminance adaptation rate
				/>
			</EffectComposer> */}

			<ContextBridge>
				<Camera ref={cameraRef} />
				<SpartanInExperience cameraRef={cameraRef} />

				<HtmlWithContext position={[0, 800, -100]} fullscreen >
					<Home section='home' style={{ opacity: 0 }} />
				</HtmlWithContext>

				<HtmlWithContext position={[0, 600, -100]} fullscreen style={{ display: context.showExampleUI ? 'block' : 'none' }}>
					<Examples section='portfolio' />
				</HtmlWithContext>

				<HtmlWithContext position={[0, 400, -100]} fullscreen >
					<Faq section='about' />
				</HtmlWithContext >

				<HtmlWithContext position={[0, 200, -100]} fullscreen>
					{/* <Pricing section='pricing' /> */}
					{
						device == 'small' ? <Pricing2 section='pricing' /> : <Pricing section='pricing' />
					}

				</HtmlWithContext>

				<HtmlWithContext position={[0, 0, -100]} fullscreen>
					<Contact section='contact' />
				</HtmlWithContext>
			</ContextBridge >
		</ Canvas >
	)
}

import React, { useEffect, useState, useContext, } from "react";
import { useNavigate } from 'react-router-dom';
import { WindowSizeContext } from "./WindowSizeContext";
import { useDetectGPU } from '@react-three/drei';

export const sections = ['home', 'portfolio', 'about', 'pricing', 'contact'];
export const numSections = sections.length;
export const minY = 0;
export const maxY = 5000;

export const SpInContext = React.createContext();
export const SpInContextBridged = React.createContext();

let initialHash = window?.location?.hash?.replace('#', '') ?? '';

export const SpInContextProvider = ({ children }) => {
	const spInNavigator = useNavigate();
	const { device } = useContext(WindowSizeContext);
	const GPUTier = useDetectGPU();



	// TODO: When i add back the option to start on a certain section, i need to rethink this for reloads
	if (initialHash == '') { //CONRAD I ADDED THIS BECAUSE SHIT SUCKED
		initialHash = 'home';
	}

	const [deltaY, _setDeltaY] = useState(sections.includes(initialHash) ? sections.indexOf(initialHash) * (maxY / numSections) : 0);

	const calculateSectionIndex = (progress) => Math.min(Math.floor(progress * numSections), numSections - 1);

	const setDeltaY = (total, currentSec) => {
		const progress = total / maxY;
		const sectionIndex = calculateSectionIndex(progress);

		if (sections[sectionIndex] !== currentSec) {
			setLastSection(currentSec);
			setCurrentSection(sections[sectionIndex]);
		}

		_setDeltaY(total);
	}

	const jumpTo = (index) => {
		const sectionDelta = maxY * (index / numSections);
		setDeltaY(sectionDelta, currentSection)
	}

	const [currentSection, setCurrentSection] = useState(initialHash);
	const [lastSection, setLastSection] = useState(null);
	const [timelines, setTimelines] = useState({});
	const [startHome, setStartHome] = useState(false);
	const [cameraPan, setCameraPan] = useState(false);
	const [allowScrolling, setAllowScrolling] = useState(false);
	const [includeMouseTracer, setIncludeMouseTracer] = useState(!GPUTier.isMobile);
	const [includeMobileSwipeIndicator, setIncludeMobileSwipeIndicator] = useState(!GPUTier.isMobile);
	const [loading, setLoading] = useState(true);
	const [isInExperience, setIsInExperience] = useState(false);
	const [showExampleUI, setShowExampleUI] = useState(false);

	useEffect(() => {
		setIncludeMobileSwipeIndicator(false);
	}, [deltaY]);

	return (
		<SpInContext.Provider value={{
			currentSection, lastSection, maxY, numSections,
			timelines, setTimelines,
			deltaY, setDeltaY,
			startHome, setStartHome,
			cameraPan, setCameraPan,
			allowScrolling, setAllowScrolling,
			includeMouseTracer, setIncludeMouseTracer,
			includeMobileSwipeIndicator, setIncludeMobileSwipeIndicator,
			loading, setLoading,
			isInExperience, setIsInExperience,
			showExampleUI, setShowExampleUI,
			spInNavigator,
			jumpTo
		}}>
			{children}
		</SpInContext.Provider>
	);
};

export const SpInContextProviderBridge = ({ value, children }) => (
	<SpInContextBridged.Provider value={{ ...value }}>
		{children}
	</SpInContextBridged.Provider>
);


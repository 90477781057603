import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './lottie.scss';

export const SwipeDownLottie = ({ play, style }) => {
	const containerRef = useRef(null);
	const animationRef = useRef(null);

	// useEffect(() => {
	// 	if (animationRef.current) {
	// 		if (play) {
	// 			animationRef.current.play();
	// 		} else {
	// 			animationRef.current.goToAndStop(0);
	// 		}
	// 	}
	// }, [play]);

	useEffect(() => {
		// animationRef.current = lottie.loadAnimation({
		// 	container: containerRef.current,
		// 	renderer: 'svg',
		// 	loop: true,
		// 	autoplay: false,
		// 	path: '/assets/lottie/swipeDownLottie.json',
		// });

		// animationRef.current.setSpeed(1);

		const resizeHandler = () => {
			// animationRef.current.resize();
		};

		// animationRef.current.play();

		window.addEventListener('resize', resizeHandler);

		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{/* <div ref={containerRef} className="lottie-svg-container"
				style={{
					alignSelf: 'center',
					background: 'transparent',
					width: style?.width ? style.width : '5rem',
					height: style?.height ? style.height : '5rem'
				}
				}>
			</div> */}
			<div style={{ textAlign: 'center', opacity: 0.5 }}>
				<p style={{ margin: '0.5rem' }}>Swipe
				</p>
			</div>
		</div>
	);
};

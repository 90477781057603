import React, { useContext } from 'react';
import { Html } from "@react-three/drei"
import { SpInContext, SpInContextProviderBridge } from "../Context"
import { WindowSizeContext, WindowSizeContextProviderBridge } from '../WindowSizeContext';

export const HtmlWithContext = React.forwardRef((props, ref) => {
	const context = useContext(SpInContext)
	const windowContext = useContext(WindowSizeContext)

	return (
		<Html ref={ref} {...props}>
			<WindowSizeContextProviderBridge value={{ ...windowContext }}>
				<SpInContextProviderBridge value={{ ...context }}>
					{props.children}
				</SpInContextProviderBridge>
			</WindowSizeContextProviderBridge>
		</Html>
	)
})
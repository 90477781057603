import { useRef, forwardRef, useEffect } from 'react';
import gsap from 'gsap';
import './tile-cover.scss';
import '../other.scss';
import { Carrot } from '../svg/Carrot';
import { useLocation, useNavigate } from 'react-router-dom';
import { exampleRoutes } from '../router';
import { spartanStore } from '../store';
import { LoadingNoState } from '../Components/Loading/Loading';
import { useLayoutEffect } from 'react';

export const TileCoverNavi = () => {
	const setIsLoadingExample = spartanStore(state => state.setIsLoadingExample);
	const tileCover = useRef();

	let tiles = {};

	for (let i = 0; i < 10; i++) {
		for (let j = 0; j < 10; j++) {
			tiles[`${i}${j}`] = useRef();
		}
	}

	const numRows = Array(10).fill(0);
	const numCols = Array(10).fill(0);

	const navBoi = useNavigate();
	const locBoi = useLocation();
	const leftDot = useRef();
	const leftBar = useRef();
	const rightBar = useRef();

	const leftAnim = useRef();
	const rightAnim = useRef();

	const navLeftRef = useRef();
	const navRightRef = useRef();
	const exitExampleRef = useRef();

	useEffect(() => {
		gsap.fromTo([navLeftRef.current, navRightRef.current, exitExampleRef.current],
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				duration: 1,
			}
		)

		leftAnim.current = gsap.fromTo('.left .dot svg polygon',
			{ opacity: 1 },  // Initial state
			{
				duration: 0.3,
				opacity: 0,
				yoyo: true,
				repeat: 1  // Repeat once to go back to the initial state
			}).pause();

		rightAnim.current = gsap.fromTo('.right .dot svg polygon',
			{ opacity: 1 }, // Initial state
			{
				duration: 0.3,
				opacity: 0,
				yoyo: true,
				repeat: 1  // Repeat once to go back to the initial state
			}).pause();

	}, [])

	const navigate = (num) => {
		var index = exampleRoutes.findIndex((route) => route.path === locBoi.pathname);
		index = index + num;

		if (index < 0) {
			// leftAnim.current.play();
			index = exampleRoutes.length - 1;
		}

		if (index > exampleRoutes.length - 1) {
			// rightAnim.current.play();
			index = 0;
		}

		// tileCover.current.style.zIndex = 2147483646;
		// tileCover.current.style.opacity = 1;
		// flip(num < 0);

		// setTimeout(() => {
		// 	setTimeout(() => {
		// 		tileCover.current.style.opacity = 0;
		// 		tileCover.current.style.zIndex = -1;
		// 		resetFlip()
		// 	}, 300);

		// 	setIsLoadingExample(true)
		// 	navBoi(exampleRoutes[index].path, { replace: true })
		// }, 0)


		const body = document.querySelector('.loading-wrapper');
		//set zIndex of parent of body 
		body.parentElement.style.zIndex = 2147483646;

		gsap.to([body, body.parentElement], { duration: 1, autoAlpha: 1 })
			.then(() => {
				setIsLoadingExample(true)
				navBoi(exampleRoutes[index].path, { replace: true })
			})
	}

	const flipTile = (ref, delay) => {
		if (ref.current.className === 'flip-card-inner flip-card-show') {
			ref.current.className = 'flip-card-inner';
		} else {
			setTimeout(() => {
				ref.current.className = 'flip-card-inner flip-card-show';
			}, delay)
		}
	}

	const calcWeight = (row, col, isForward) => {
		const clickIndex = isForward ? 0 : 9;

		if (row === 4 && col === clickIndex) {
			return 100
		}

		const delayStrength = 100;
		const vertDistanceFromMiddle = Math.abs(row + 1 - 5);
		const horDistanceFromClick = Math.abs(col + 1 - (clickIndex + 1));
		const delay = (delayStrength * vertDistanceFromMiddle) + ((delayStrength / 2) * horDistanceFromClick);

		return Math.min(delay + (Math.random() * 1000), 1000);
	}

	const flip = (isForward) => {
		for (let i = 0; i < 10; i++) {
			for (let j = 0; j < 10; j++) {
				flipTile(tiles[`${i}${j}`], calcWeight(i, j, isForward));
			}
		}
	}

	const resetFlip = () => {
		for (let i = 0; i < 10; i++) {
			for (let j = 0; j < 10; j++) {
				flipTile(tiles[`${i}${j}`], 0);
			}
		}

	}

	const calcTop = (rowIndex) => {
		return `${rowIndex * 10}vh`;
	}

	const calcLeft = (colIndex) => {
		return `${colIndex * 10}vw`;
	}

	function handleExit() {
		const body = document.querySelector('.loading-wrapper');
		//set zIndex of parent of body 
		body.parentElement.style.zIndex = 2147483646;

		gsap.to([body, body.parentElement], { duration: 1, autoAlpha: 1 })
			.then(() => {
				window.location.replace('/#portfolio')
			})
	}

	useLayoutEffect(() => {
		// console.log("NOW")
		// const body = document.querySelector('.loading-wrapper');
		// gsap.to(body, { duration: 1, autoAlpha: 0 })
	}, [])

	return (
		<>
			{/* <LoadingNoState /> */}
			<div>
				<div ref={tileCover} style={{ height: '100%', width: '100%', opacity: 0, position: 'absolute', zIndex: -1 }}>
					{
						numRows.map((row, i) => numCols.map((col, j) => <FlipDiamond key={`${i}${j}`} top={calcTop(i)} left={calcLeft(j)} ref={tiles[`${i}${j}`]} />))
					}
				</div>

				<div className={"example-nav-container"}>
					<div ref={navLeftRef} className="left">
						<div ref={leftDot} className="dot" onClick={() => navigate(-1)}>
							<Carrot />
						</div>
					</div >

					<div ref={exitExampleRef} className={'exit-example'} onClick={handleExit}>
						<p>EXIT</p>
					</div>

					<div ref={navRightRef} className="right">
						<div className="dot" onClick={() => { navigate(1) }}>
							<Carrot />
						</div>
					</div>
				</div>

			</div>
		</>
	)
}

const FlipDiamond = forwardRef((props, ref) =>
	<>
		<div className="flip-card" style={{ top: props.top, left: props.left }}>
			<div ref={ref} className="flip-card-inner ">
				<div className="flip-card-front"></div>
				<div className="flip-card-back"></div>
			</div>
		</div>
	</>
);
import { useContext } from 'react';
import { SpInContext, maxY, numSections } from '../../Context';
import './section-indicator.scss';

export const SectionIndicator = () => {
	return (
		<div className='ticks-container'>
			<TickMark section='home' index={0} />
			<TickMark section='portfolio' index={1} />
			<TickMark section='about' index={2} />
			<TickMark section='pricing' index={3} />
			<TickMark section='contact' index={4} />
		</div>
	)
}

const TickMark = ({ section, index }) => {
	const { currentSection, setDeltaY } = useContext(SpInContext);

	const jumpTo = (index) => {
		const sectionDelta = maxY * (index / numSections);
		setDeltaY(sectionDelta, currentSection)
	}

	return (
		<div className='tick-container' onClick={() => { jumpTo(index) }}>
			<div className='tick' style={{ opacity: section === currentSection ? 1 : 0.2 }}></div>
		</div>
	)
}
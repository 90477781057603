import { useRef, useEffect, useState, useContext } from 'react';
import gsap from 'gsap';
import './navigation-bar.scss';
import { SpInContext, numSections, maxY } from '../../../Context';


export const NavigationBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const drawerAnimation = useRef();
	const navBarInnerWrapperRef = useRef();

	const { setDeltaY, currentSection, startHome, jumpTo } = useContext(SpInContext)

	useEffect(() => {
		if (startHome == true) {
			drawerAnimation.current = gsap.timeline().to(navBarInnerWrapperRef.current, {
				duration: 0.8,
				delay: 0,
				ease: 'power4.Out',
				top: 0,
				onComplete: (() => {
					setIsOpen(true)
				})
			})
		}
	}, [startHome])

	return (
		<nav className="navigation-bar" >
			<div className="inner-wrapper" style={{ position: 'fixed', width: '100%', justifyContent: 'center' }} ref={navBarInnerWrapperRef}>
				<div className="content-wrapper">
					<div className="content-wrapper-inner-border" >
						<ul>
							<li style={{ width: '3rem', pointerEvents: 'none' }} />
							<li style={{ color: currentSection === 'home' ? 'red' : 'black' }} onClick={() => jumpTo(0)}>Home</li>
							<li style={{ color: currentSection === 'portfolio' ? 'red' : 'black' }} onClick={() => jumpTo(1)}>Portfolio</li>
							<li style={{ color: currentSection === 'about' ? 'red' : 'black' }} onClick={() => jumpTo(2)}>About</li>
							<li style={{ color: currentSection === 'pricing' ? 'red' : 'black' }} onClick={() => jumpTo(3)}>Pricing</li>
							<li style={{ color: currentSection === 'contact' ? 'red' : 'black' }} onClick={() => jumpTo(4)}>Contact</li>
							<li style={{ width: '3rem', pointerEvents: 'none' }} />
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}

import { useProgress } from "@react-three/drei"
import { useEffect, useRef } from "react";
import { spartanStore } from "./store";
import gsap from "gsap";

export const ConradsCustomSuspense = (props) => {
	const { progress } = useProgress();
	const loadingElement = useRef();
	const isLoadingExample = spartanStore(state => state.isLoadingExample);
	const setIsLoadingExample = spartanStore(state => state.setIsLoadingExample);

	useEffect(() => {
		if (isLoadingExample) {
			loadingElement.current.style.opacity = 1;
			loadingElement.current.style.zIndex = 2147483647;
		}

		if (progress === 100) {
			setIsLoadingExample(false);

			gsap.to(loadingElement.current, {
				autoAlpha: 0,
				duration: 1,
				delay: 2.5,
				ease: 'power3.in'
			}).then(() => {
				// loadingElement.current.style.zIndex = 0;
			})
		}
	}, [progress, isLoadingExample]);

	return (
		<>
			<div ref={loadingElement} style={{ position: 'absolute', zIndex: 2147483647 }}>
				{props.fallback}
			</div>
			{props.children}
		</>
	)
}
import { Backdrop } from '@react-three/drei'
import { useRef, useLayoutEffect } from 'react'
import { useFrame } from '@react-three/fiber'

export default function Backdrop1() {

    const backdropRef = useRef()
    const yOffset = -0.25

    return <>
        <Backdrop
			floor={2}
			scale={[12, 4, 1]}
			segments={20}
			position={[0, yOffset, -2]}
			receiveShadow={true}
		>
			<meshStandardMaterial color={'#ffffff'} receiveShadow/>
		</Backdrop>
    </>
}
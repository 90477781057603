import React, { useEffect, useRef, useContext, useState } from 'react';
import './pricing.scss';
import './pricing2.scss';
import { SpInContextBridged } from '../../../Context';
import { WindowSizeContextBridged } from '../../../WindowSizeContext';
import { useAdjustNavOffset } from '../../../hooks/useAdjustOffsets';
import { useScrollHandler } from '../../../hooks/useScrollHandler';
import { gsap } from 'gsap';

export const Pricing2 = ({ section }) => {
	const pricingRef = useRef(null);
	const cardListRef = useRef();
	const { timelines, setDeltaY, currentSection, startHome, jumpTo } = useContext(SpInContextBridged);
	const { handleWheel, handleDragStart, handleDragEnd } = useScrollHandler(cardListRef);
	timelines[section] = useRef();


	const title = useRef();
	useEffect(() => {
		const staggerSpeed = 0.3;
		const easing = "power2.inOut";

		timelines[section].current = gsap.timeline({ paused: true })
			.add('start')
			.to(pricingRef.current, { autoAlpha: 1, ease: easing, duration: 0.5 })
			.add('move-cards')
			.to(title.current, { opacity: 1, ease: easing, duration: 0.5 })
			.add('middle')
			.to(title.current, { opacity: 0, ease: easing, duration: 0.5 }, 'middle')
			.to(pricingRef.current, { autoAlpha: 0, ease: easing, duration: 0.5, delay: 0.5 }, 'middle')
			.add('end');

	}, []);



	return (
		<section ref={pricingRef} className="section pricing">
			<div ref={cardListRef} className="cardList" style={{ overflowX: 'auto', display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
				<div ref={title} className={'title pricing-title section-header'} style={{ alignSelf: 'self-start', marginLeft: '6%' }}>
					<h1 style={{ fontWeight: 100, zIndex: 9999999, position: 'relative', margin: 0, textAlign: 'left', marginLeft: '0%', marginTop: '3rem' }}>PRICING</h1>
				</div>
				<PricingCard
					title='Custom'
					summary='Immersive 3D Experience'
					features={['Fully Custom 3D Website', 'Advanced texture, Lighting & Shaders', 'Integrated Animations & Transitions', 'Mobile, Desktop, AR/VR (Optional)']}
					priceHeader='Pricing'
					price='Pricing By Project'
					delivery='TBD'
				/>
				<PricingCard
					title='Standard'
					summary='Integrate 3D elements into most Front-end Frameworks'
					features={['Multiple 3D models', 'Basic Interactivity (Rotation, Zoom)', 'Standard Textures & Lighting', 'Desktop & Mobile Compatibility']}
					priceHeader='Starting at'
					price='Starting at $5,000'
					delivery='3-5 weeks'
				/>
				<PricingCard
					title='Stand Alone'
					summary='3D Viewer'
					features={['Single 3D Model', 'Dedicated Sub-Domain', 'Standard Textures & Lighting', '360 Degree Viewing', 'Mobile, Desktop Compatibility']}
					priceHeader='Starting at'
					price='Starting at $3,000'
					delivery='1-2 weeks'
				/>
			</div>
		</section>
	);
};


const PricingCard = ({ title, summary, features, price, delivery, priceHeader }) => {
	const { device } = useContext(WindowSizeContextBridged);
	const { jumpTo } = useContext(SpInContextBridged);

	return (
		<div className="pricing-card" style={{ width: '75vw', display: 'inline-block', background: 'whitesmoke', padding: '1rem 0.5rem 1rem 0.5rem', borderRadius: '8px', border: '1px solid rgba(0,0,0,0.2)', marginBottom: '2rem !important', maxWidth: '300px' }}>
			<div className="type">{title}</div>
			<div className="tag-line">
				<p>{summary}</p>
			</div>
			<div className="content" style={{ overflow: 'hidden' }}>
				<div className='page'>
					<div className='page-inner'>
						<div className="features-container">
							<div className="tag sub-page ">Features</div>
							<ul>
								{features.map((feature, index) => (
									<li key={index} className="sub-page " style={{ textWrap: 'wrap' }}>{feature}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div style={{ padding: '0.25rem', textAlign: 'center' }}>
				{price}
			</div>
			<div className="learn-more" onClick={() => jumpTo(4)}>
				<div>Get a Quote</div>
			</div>

			<div className="page">
				<div className='page-inner'>
					<div className="timeline sub-page">
						<p>Est. Delivery: {delivery}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { Html } from '@react-three/drei'

export default function Lights(props) {
    const color = new THREE.Color('#ffdddd')
    const ambientLightRef = useRef()
    const keyLightRef = useRef()
    const fillLightRef = useRef()
    const backLightRef = useRef()
    const targetRef = useRef()
    const movementFactorX = 0.7 //how fast the light follows the mouse
    const movementFactorY = 0.5 //how fast the light follows the mouse
    const targetFactor = 0.2 //how fast the target moves to the new position
    const movementSpeed = 0.2 //how fast the light moves to the new position
    const keyLightPosition = [1.6, 1.4, 0]
    const fillLightPosition = [-1.6, 1.4, -0.8]
    const backLightPosition = [-1.2, 1.5, -2.2]
    let useMouse = false

    const alphaRef = useRef(null);
    const betaRef = useRef(null);
    const gammaRef = useRef(null);

    function handleOrientation(event) {
        const alpha = event.alpha; // z-axis

        // Clamp beta to [-85, 85]
        const beta = Math.min(Math.max(event.beta, -70), 70);   // x-axis

        // Clamp gamma to [-45, 45]
        const gamma = Math.min(Math.max(event.gamma, -45), 45); // y-axis
        const factor = 40;

        // Print values to divs
        if (alphaRef.current) alphaRef.current.innerText = `Alpha: ${alpha}`;
        if (betaRef.current) betaRef.current.innerText = `Beta: ${beta}`;
        if (gammaRef.current) gammaRef.current.innerText = `Gamma: ${gamma}`;

        if (keyLightRef.current && fillLightRef.current && backLightRef.current) {
            gsap.to(keyLightRef.current.position, {
                x: keyLightPosition[0] + (gamma / factor),
                y: keyLightPosition[1] + (beta / factor),
                duration: 0.2,
            })

            gsap.to(fillLightRef.current.position, {
                x: fillLightPosition[0] + (gamma / factor),
                y: fillLightPosition[1] + (beta / factor),
                duration: 0.2,
            })

            gsap.to(backLightRef.current.position, {
                x: backLightPosition[0] + (gamma / factor),
                y: backLightPosition[1] + (beta / factor),
                duration: 0.2,
            })
        }
    }

    window.addEventListener('deviceorientation', orientationHandler);

    function orientationHandler(event) {
        if (event.alpha !== null && event.beta !== null && event.gamma !== null) {
            handleOrientation(event);
        } else {
            console.log('use mouse');
            useMouse = true;
            window.removeEventListener('deviceorientation', orientationHandler);
        }
    }

    useFrame((state) => {
        if (targetRef.current && keyLightRef.current && fillLightRef.current && backLightRef.current) {

            keyLightRef.current.target = targetRef.current
            fillLightRef.current.target = targetRef.current
            backLightRef.current.target = targetRef.current
        }
    })

    useFrame((state) => {
        if (targetRef.current && keyLightRef.current && fillLightRef.current && backLightRef.current && useMouse) {

            gsap.to(keyLightRef.current.position, {
                x: (state.mouse.x * movementFactorX + keyLightPosition[0]),
                y: (state.mouse.y * movementFactorY + keyLightPosition[1]),
                duration: movementSpeed,
            })

            gsap.to(fillLightRef.current.position, {
                x: (state.mouse.x * movementFactorX + fillLightPosition[0]),
                y: (state.mouse.y * movementFactorY + fillLightPosition[1]),
                duration: movementSpeed,
            })

            gsap.to(backLightRef.current.position, {
                x: (state.mouse.x * movementFactorX + backLightPosition[0]),
                y: (state.mouse.y * movementFactorY + backLightPosition[1]),
                duration: movementSpeed,
            })
        }
    })




    return (
        <>
            {/* <Html>
                <div ref={alphaRef}>Alpha: </div>
                <div ref={betaRef}>Beta: </div>
                <div ref={gammaRef}>Gamma: </div>
            </Html> */}

            <ambientLight ref={ambientLightRef} intensity={0.3} />
            {/* 0.4 */}

            <spotLight
                ref={keyLightRef}
                color={0xffffff}
                intensity={2} //2
                position={keyLightPosition}
                angle={Math.PI / 6}
                castShadow
                penumbra={1}
                target={targetRef.current}
            />

            <spotLight
                ref={fillLightRef}
                color={0xffffff}
                intensity={1} //1
                position={fillLightPosition}
                angle={Math.PI / 6}
                castShadow
                penumbra={1}
                target={targetRef.current}
            />

            <spotLight
                ref={backLightRef}
                color={0xffffff}
                intensity={0.5} //0.5
                position={backLightPosition}
                angle={Math.PI / 6}
                castShadow
                penumbra={1}
                target={targetRef.current}
            />


            {/* <mesh position={[1.6, 1.4, 0]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color={'#ff0000'} />
            </mesh>

            <mesh position={[-1.6, 1.4, -0.8]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color={'#00ff00'} />
            </mesh>

            <mesh position={[-1.2, 0.5, -2.2]}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color={'#0000ff'} />
            </mesh> */}

            <mesh ref={targetRef} position={[0, 0, -1]} visible={false}>
                <sphereGeometry args={[0.05, 32, 32]} />
                <meshBasicMaterial color={'#ffffff'} />
            </mesh>
        </>
    )
}

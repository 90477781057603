
import { useState, useEffect, useRef, useContext } from 'react';
import gsap from 'gsap';
import './../sections.scss';
import './contact.scss';
import { SpInContextBridged } from '../../../Context';
import { TwitterX } from '../../../svg/TwitterX';
import { LinkedIn } from '../../../svg/LinkedIn';
import axios from 'axios';
import Swal from 'sweetalert2';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
import { WindowSizeContextBridged } from '../../../WindowSizeContext';
import { LoadingIcon } from '../../../svg/LoadingIcon';
// import { LoadingIcon } from '../../../assets/svg/LoadingIcon';
import { useAdjustNavFooterOffset } from '../../../hooks/useAdjustOffsets';
import { Footer } from '../../Footer/Footer';
import { UpCarrot } from '../../../svg/UpCarrot';

export const Contact = ({ section }) => {
	const { device } = useContext(WindowSizeContextBridged)
	const { lastSection, currentSection, timelines, setTimelines } = useContext(SpInContextBridged);
	const contactRef = useRef();
	const bordersRef = useRef();
	const bodyContentRef = useRef();
	const submitButtonRef = useRef();
	const submitButtonAnimationRef = useRef();
	timelines[section] = useRef();

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		projectDescription: "",
	});

	const emailErrorMsg = "Please enter a valid email address"
	const [emailError, setEmailError] = useState(false);
	const [sending, setSending] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value })
	};

	const validateEmail = (email) => {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(email).toLowerCase())
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!validateEmail(formData.email)) {
			setEmailError(true)
		} else {
			setEmailError(false);
			setSending(true);

			axios.post(
				'https://www.spartan-in.com/contact/send',
				JSON.stringify({ fromEmail: formData.email, message: formData.projectDescription, name: formData.name, subject: '' }),
				{
					headers: {
						"Access-Control-Allow-Origin": '*',
						"Access-Control-Allow-Methods": '*',
						"Content-Type": "application/json",
						"accept": "application/json",
					}
				}
			)
				.then((response) => {
					if (response) {
						Swal.fire({
							icon: 'success',
							title: 'Email Sent',
							text: 'We will get back to you shortly.',
							showConfirmButton: false,
							color: 'white',
							background: 'black',
							timer: 3000,
							timerProgressBar: true
						})
					}
					setSending(false);
				})
				.catch((error) => {
					Swal.fire({
						icon: 'error',
						title: 'There was an error sending your email',
						showConfirmButton: false,
						color: 'white',
						background: 'black',
						timer: 2500,
						timerProgressBar: true,
					})
					setSending(false);
				})
		}
	};

	useEffect(() => {
		timelines[section].current = gsap.timeline().pause()
			.add('start')
			.fromTo(contactRef.current, {
				autoAlpha: 0,
			}, {
				autoAlpha: 1,
				duration: 0.25,
			})
			.fromTo(bodyContentRef.current, {
				autoAlpha: 0,
				height: 0
			}, {
				duration: 0.5,
				autoAlpha: 1,
				height: 'auto',
			})
			.add('middle')
			.to(bodyContentRef.current, {
				height: 0,
				duration: 0.5,
				autoAlpha: 0,
			})
			.to(contactRef.current, {
				autoAlpha: 0,
				duration: 0.25,
			})
			.add('end');

		submitButtonAnimationRef.current = gsap.timeline().to(bodyContentRef.current, {
			height: 'auto',
			duration: 0.25,
		}).to(submitButtonRef.current, {
			width: 'auto',
			duration: 0.25
		}).pause();
	}, [])

	useEffect(() => {
		if (submitButtonAnimationRef.current) {
			if (sending) {
				submitButtonAnimationRef.current.play();
			} else {
				submitButtonAnimationRef.current.reverse();
			}
		}
	}, [sending])

	return (
		<>
			<section className={device == "small" ? "section contact contact-mobile" : "section contact"} ref={contactRef} onLoad={useAdjustNavFooterOffset(contactRef)} style={{ opacity: 0 }}>
				<div className={"contact-content-wrapper"}>
					<div className="title contact-title section-header">
						<h1>CONTACT</h1>
					</div>

					<div className="card-body"  >
						<div className="card-body-content" ref={bodyContentRef} style={{ height: 0, flexDirection: 'column', overflow: 'hidden !important', justifyContent: 'center', alignItems: 'center' }}>
							<div className="card-body-content-inner" style={{ maxWidth: '75vw' }}>

								<div className="contact-statement">
									<p> Have a Vision? </p>
									<p>Let's Make it Happen.</p>
								</div>
								<form className="contact-form" autoComplete="off" onSubmit={handleSubmit}>

									<label>
										<input
											placeholder='Name'
											type="text"
											name="name"
											value={formData.name}
											onChange={handleChange}
										/>
									</label>

									<label>
										<textarea
											rows={5}
											placeholder='Message'
											name="projectDescription"
											value={formData.projectDescription}
											onChange={handleChange}
										/>
									</label>

									<div className={"bottom-div"}>

										<label>
											<input
												placeholder='Email'
												type="email"
												name="email"
												value={formData.email}
												onChange={handleChange}
											/>
										</label>


										<div className={"submit-button-wrapper"}>
											<button type="submit" ref={submitButtonRef} >
												{
													sending ?
														<div className='submit-inner'>
															<LoadingIcon style={{ width: '100%' }} />
														</div>
														:
														<div className='submit-email-inner' style={{ transform: 'rotate(90deg)' }}>
															<UpCarrot />
														</div>
												}
											</button>
										</div>
									</div>
								</form >

								<div className="info-div" style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5rem', paddingTop: '0.5rem', justifyContent: 'space-between' }}>
									<div className="socials-container">
										<a href="https://twitter.com/Spartan_in_3D" target="_blank" rel="noopener noreferrer">
											<TwitterX />
										</a>
										<a href="https://www.linkedin.com/company/spartan-innovations-llc/" target="_blank" rel="noopener noreferrer" >
											<LinkedIn />
										</a>
									</div>

									<div className='business-info' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<p style={{ margin: 0, fontSize: 'small', textAlign: 'center' }}>+1 (727) 222-3120</p>
										<p style={{ margin: 0, fontSize: 'small', textAlign: 'center' }}>info@spartan-in.com</p>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

			</section >
			<Footer />
		</>
	);
};

export default Contact;

{/* {device == "small" ? <></> :
											<div className='business-info'>
												<p>Business Number:</p>
												<p style={{ marginLeft: '10px' }}>+1 (727) 222-3120</p>
											</div>
										} */}

// import React from 'react'
// import ReactDOM from 'react-dom/client'
// import App from './App.jsx'
// import './index.css'

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// )


import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.scss';
import { SpInContextProvider } from './Context';
import { WindowSizeContextProvider } from './WindowSizeContext';
import { router } from './router.jsx';
import { RouterProvider } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// <React.StrictMode>
	// <WindowSizeContextProvider>
	// 	<SpInContextProvider>
	// 		<App />
	// 	</ SpInContextProvider>
	// </WindowSizeContextProvider>
	// </React.StrictMode>
	<RouterProvider router={router} />
);

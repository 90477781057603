import * as THREE from 'three'
import { useEffect, useRef, useMemo, forwardRef, useLayoutEffect, useContext, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import { PerspectiveCamera, useDetectGPU } from "@react-three/drei"
import gsap from 'gsap'
import { SpInContext } from '../../Context'

const Camera = forwardRef((props, ref) => {
	const { camera } = useThree()
	const cameraTargetRef = useRef()
	const panAnimationRef = useRef()
	const cameraPanCountRef = useRef()
	const [cameraPanCount, setCameraPanCount] = useState(0)
	const { currentSection, cameraPan, startHome } = useContext(SpInContext)
	const GPUTier = useDetectGPU()


	const { position, near, far, fov } = useControls('Camera', {
		position: { value: { x: 0, y: 800, z: 0 }, step: 0.1, min: -20, max: 20 },
		near: 0.01,
		far: 1000,
		fov: 55,
	})

	// * Maths for finding html position
	// let unitVector = new THREE.Vector3(0, 0, 0)
	// const cameraPos = new THREE.Vector3(-10, 10, 70)
	// const cameraTargetPos = new THREE.Vector3(-10, 3, 0)
	// const distanceVector = cameraPos.clone().sub(cameraTargetPos.clone())
	// unitVector = distanceVector.clone().divideScalar(distanceVector.length())
	// const finalVector = unitVector.clone().multiplyScalar(distanceVector.length() - 3)
	// console.log(finalVector.add(cameraTargetPos.clone()))


	const positions = useMemo(() => {
		return {
			'#home': { x: 0, y: 800, z: 0 },
			'#about': { x: 0, y: 400, z: 0 },
			'#pricing': { x: 0, y: 200, z: 0 },
			'#contact': { x: 0, y: 0, z: 0 },
			'#portfolio': { x: 0, y: 600, z: 0 },
		}
	}, []);

	useLayoutEffect(() => {
		const hash = window.location.hash; // e.g., "#home", "#about", etc.
		const startPos = positions[hash] || { x: 0, y: 800, z: 0 }; // Fallback to a default position

		camera.position.set(startPos.x, startPos.y, startPos.z);
		const cameraTarget = new THREE.Vector3(0, startPos.y, -60);
		cameraTargetRef.current = cameraTarget;
		camera.fov = fov;
		camera.lookAt(cameraTargetRef.current);
		cameraPanCountRef.current = 0;
	}, []);

	useEffect(() => {
		if (cameraPan == true) {
			Pan()
		}
	}, [cameraPan])

	function Pan() {

		if (GPUTier.isMobile) { //if mobile and narrow screen disable pan
			if (window.innerWidth < 767) {
				return
			}
		}

		// const duration = 20
		// if (cameraPanCountRef.current == 0) {
		// 	panAnimationRef.current = gsap.timeline({ repeat: -1, yoyo: false })
		// 	panAnimationRef.current.to(camera.position, {
		// 		x: '+=0.4',
		// 		ease: 'power1.out',
		// 		duration: duration,
		// 		onUpdate: (() => {
		// 			camera.lookAt(cameraTargetRef.current)
		// 		})
		// 	})
		// 	panAnimationRef.current.to(camera.position, {
		// 		x: '-=0.4',
		// 		ease: 'power1.in',
		// 		duration: duration,
		// 		onUpdate: (() => {
		// 			camera.lookAt(cameraTargetRef.current)
		// 		})
		// 	})
		// 	panAnimationRef.current.to(camera.position, {
		// 		x: '-=0.4',
		// 		ease: 'power1.out',
		// 		duration: duration,
		// 		onUpdate: (() => {
		// 			camera.lookAt(cameraTargetRef.current)
		// 		})
		// 	})
		// 	panAnimationRef.current.to(camera.position, {
		// 		x: '+=0.4',
		// 		ease: 'power1.in',
		// 		duration: duration,
		// 		onUpdate: (() => {
		// 			camera.lookAt(cameraTargetRef.current)
		// 		})
		// 	})
		// }
	}


	function Home() {
		cameraPanCountRef.current += 1
		const cameraHome = gsap.to(camera.position, {
			duration: 1,
			ease: 'power2.inOut',
			x: positions['#home'].x,
			y: positions['#home'].y,
			z: positions['#home'].z,
			onComplete: (() => {
				cameraPanCountRef.current -= 1
				Pan()
			})
		})
		const cameraTargetHome = gsap.to(cameraTargetRef.current, {
			duration: 1,
			ease: 'power2.inOut',
			x: 0,
			y: 800,
			z: -100,
			onUpdate: (() => {
				camera.lookAt(cameraTargetRef.current)
			})
		})
	}

	function Examples() {
		cameraPanCountRef.current += 1
		const cameraExamples = gsap.to(camera.position, {
			duration: 1,
			ease: 'power2.inOut',
			x: positions['#portfolio'].x,
			y: positions['#portfolio'].y,
			z: positions['#portfolio'].z,
			onComplete: (() => {
				cameraPanCountRef.current -= 1
				Pan()
			})
		})
		const cameraTargetExamples = gsap.to(cameraTargetRef.current, {
			duration: 1,
			ease: 'power2.inOut',
			x: 0,
			y: 600,
			z: -100,
			onUpdate: (() => {
				camera.lookAt(cameraTargetRef.current)
			})
		})
	}

	function Faq() {
		setCameraPanCount(cameraPanCount + 1)

		cameraPanCountRef.current += 1
		const cameraServices = gsap.to(camera.position, {
			duration: 1,
			ease: 'power2.inOut',
			x: positions['#about'].x,
			y: positions['#about'].y,
			z: positions['#about'].z,
			onComplete: (() => {
				cameraPanCountRef.current -= 1
				Pan()
			})
		})
		const cameraTargetServices = gsap.to(cameraTargetRef.current, {
			duration: 1,
			ease: 'power2.inOut',
			x: 0,
			y: 400,
			z: -100,
			onUpdate: (() => {
				camera.lookAt(cameraTargetRef.current)
			})
		})
	}

	function Pricing() {
		cameraPanCountRef.current += 1
		const cameraTeam = gsap.to(camera.position, {
			duration: 1,
			ease: 'power2.inOut',
			x: positions['#pricing'].x,
			y: positions['#pricing'].y,
			z: positions['#pricing'].z,
			onComplete: (() => {
				cameraPanCountRef.current -= 1
				Pan()
			})
		})
		const cameraTargetTeam = gsap.to(cameraTargetRef.current, {
			duration: 1,
			ease: 'power2.inOut',
			x: 0,
			y: 200,
			z: -100,
			onUpdate: (() => {
				camera.lookAt(cameraTargetRef.current)
			})
		})
	}

	function Contact() {
		cameraPanCountRef.current += 1
		const cameraContact = gsap.to(camera.position, {
			duration: 1,
			ease: 'power2.inOut',
			x: positions['#contact'].x,
			y: positions['#contact'].y,
			z: positions['#contact'].z,
			onComplete: (() => {
				cameraPanCountRef.current -= 1
				Pan()
			})
		})
		const cameraTargetContact = gsap.to(cameraTargetRef.current, {
			duration: 1,
			ease: 'power2.inOut',
			x: 0,
			y: 0,
			z: -100,
			onUpdate: (() => {
				camera.lookAt(cameraTargetRef.current)
			})
		})
	}


	useEffect(() => {
		// if (panAnimationRef.current) {
		// 	panAnimationRef.current.kill()
		// }
		if (currentSection == 'home') {
			if (startHome == true) {
				Home()
			}
		}
		if (currentSection == 'about') {
			Faq()
		}
		if (currentSection == 'pricing') {
			Pricing()
		}
		if (currentSection == 'contact') {
			Contact()
		}
		if (currentSection == 'portfolio') {
			Examples()
		}
	}, [currentSection])
})

export { Camera }
import { useState, useContext } from 'react'
import { useEffect } from 'react';
import { SpInContext } from './Context';
import { WindowSizeContext } from './WindowSizeContext';
import ThreeFiberCanvas from './Components/ThreeFiberCanvas';
import { Loading } from './Components/Loading/Loading';
import { NavigationBar } from './Components/Navigation/NavigationBar/NavigationBar';
import { NavigationBarMobile } from './Components/Navigation/NavigationBar/NavigationBarMobile';
import { SectionIndicator } from './Components/SectionIndicator/SectionIndicator';
import { Scroller2 } from './Components/Scroll/Scroller2';
import { MobileSwipeIndicator } from './Components/Mobile/MobileSwipeIndicator';
import MouseTracer from './Components/MouseTracer/MouseTracer';
import { Leva } from 'leva'
import { Footer } from './Components/Footer/Footer'
import { useLocation } from 'react-router-dom';

export const App = () => {
	const location = useLocation();
	const { currentSection, includeMobileSwipeIndicator, allowScrolling, setAllowScrolling } = useContext(SpInContext);
	const { device, setWindowSize } = useContext(WindowSizeContext);

	useEffect(() => {
		window.addEventListener('resize', (event) => { setWindowSize(window.visualViewport) });
		setWindowSize(window.visualViewport)
	}, [])

	// useEffect(() => {
	// 	ReactGA.set({ page: location.pathname + location.hash });
	// 	ReactGA.pageview(location.pathname + location.hash);
	// }, [location]);

	useEffect(() => {
		// Track a pageview
		window.gtag('config', 'G-VWKVLQVG3F', {
			page_path: `${location.pathname}${location.hash}`,
		});
	}, [location]);

	useEffect(() => {
		window.location.replace(`/#${currentSection}`)
		// overscrollBehaviorY was the fix for chrome, but the fix for safari works better it seems
		// leaving in case we find out its broken
		if (currentSection !== 'home') {
			document.body.style.overscrollBehaviorY = 'contain';
			document.body.style.touchAction = 'none';
		} else {
			document.body.style.overscrollBehaviorY = 'auto';
			document.body.style.touchAction = 'auto';
		}
	}, [currentSection])

	return (
		<>
			<Leva hidden={true} collapsed={true} />

			<Loading />

			{
				device === 'small' ?
					<NavigationBarMobile /> :
					<NavigationBar />
			}

			{/* NOT USED */}
			{/* {includeMobileSwipeIndicator ? <MobileSwipeIndicator /> : <></>} */}

			<ThreeFiberCanvas />

			{allowScrolling ? <Scroller2 /> : <></>}

			{
				device === 'small' ?
					<></> :
					<SectionIndicator />
			}

		</>
	)
}

export default App



import React from "react";
import gsap from "gsap";
import { useState, useEffect, useRef, useContext } from "react";
import { useContextBridge, useTexture, useGLTF, useCubeTexture, useProgress } from "@react-three/drei";
import "./loading.scss";
import { SpInContext } from "../../Context";
import { Icon } from "./Icon";
import { useDetectGPU } from '@react-three/drei'

export function Loading() {
	const { progress, active, errors, item, loaded, total } = useProgress();
	const { loading, setLoading } = useContext(SpInContext)
	const loadingTextRef = useRef();
	const loadingWrapperRef = useRef();
	const fadeOutAnimation = useRef();
	const GPUTier = useDetectGPU()

	useEffect(() => {
		fadeOutAnimation.current = gsap.to(loadingWrapperRef.current, {
			paused: true,
			duration: 1,
			autoAlpha: 0,
			ease: 'power2.in',
			onComplete: () => {
				setLoading(false);
				if (loadingWrapperRef.current) {
					// TODO causes routing to fail
					//  loadingWrapperRef.current.remove();
				}
			}
		})
	}, [])

	useEffect(() => {
		// console.log(progress, active, errors, item, loaded, total)
		//TODO -  progress stays at 0 on mobile????
		if (progress === 100 || GPUTier.isMobile) {
			fadeOutAnimation.current.play();
		}
	}, [progress])

	return (
		<div ref={loadingWrapperRef} className="loading-wrapper">
			<div className="loading">
				<div className="loading inner-div" >
					<div className="icon-wrapper">
						<Icon />
					</div>
					<h4 ref={loadingTextRef}>
						Loading
					</h4>
				</div>
			</div>
		</div>
	);
}


export function LoadingNoState() {
	const loadingWrapperRef = useRef();


	return (
		<div ref={loadingWrapperRef} className="loading-wrapper">
			<div className="loading">
				<div className="loading inner-div" >
					<div className="icon-wrapper">
						<Icon />
					</div>
					<h4>
						Loading
					</h4>
				</div>
			</div>
		</div>
	);
}

import React, { useEffect, useRef, useState } from 'react'; // Added useState
import './footer.scss';
import gsap from 'gsap';

export const Footer = () => {

  const footerRef = useRef();
  const privacyPolicyRef = useRef();

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false); // Corrected the spelling mistake
  const privacyPolicyAnimationRef = useRef();


  useEffect(() => {
    gsap.to(footerRef.current, {
      bottom: 0,
      duration: 1.5,
      ease: 'power2.Out',
      delay: 2.5
    })

    const privacyPolicyAnimation = gsap.timeline().to(privacyPolicyRef.current, {
      height: '60svh',
      duration: 0.25,
      ease: 'power2.Out',
    });
    privacyPolicyAnimationRef.current = privacyPolicyAnimation; // Assigned the animation to the ref

    // Event listener to check scroll position
    const handleScroll = (e) => {
      e.preventDefault();
      if (privacyPolicyRef.current.scrollTop === 0) {
        setPrivacyPolicyOpen(false);
      }
    };

    privacyPolicyRef.current.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      if (privacyPolicyRef.current) {
        privacyPolicyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (privacyPolicyAnimationRef.current) {
      if (privacyPolicyOpen) {
        privacyPolicyAnimationRef.current.play();
      } else {
        privacyPolicyAnimationRef.current.reverse();
      }
    }
  }, [privacyPolicyOpen]);

  return (
    <footer ref={footerRef} className='footer' style={{ bottom: -100 }}>
      <div className='footer-header'>
        <div className='copyright'>
          <p className='text'>© Spartan Innovations LLC 2023 |</p>
        </div>
        <div className="privacy-policy" onClick={() => setPrivacyPolicyOpen(!privacyPolicyOpen)}> {/* Corrected the onClick handler */}
          <p> &nbsp; Privacy Policy </p>
        </div>
      </div>
      <div ref={privacyPolicyRef} className="privacy-policy-description" style={{ height: 0 }}>
        {privacyPolicyOpen ?
          <div className="close-button" onClick={() => setPrivacyPolicyOpen(false)} style={{ cursor: 'pointer', position: 'absolute', top: '4px', right: '8px' }}>x</div>
          : <></>
        }
        <h2>Privacy Policy</h2>
        <p><strong>Spartan Innovations LLC</strong> (“we”, “us”, or “our”) respects your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.spartan-in.com. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>

        <h3>Information We Collect</h3>
        <p>We may collect information that can identify you when you use our services. The information we collect depends on how you use our services and may include:</p>
        <ul>
          <li><strong>Personal Data:</strong> We may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, etc.</li>
          <li><strong>Usage Data:</strong> We may also collect information about how the site is accessed and used. This usage data may include information such as your computer’s Internet Protocol address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</li>
        </ul>

        <h3>Cookies and Tracking Technologies</h3>
        <p><strong>Google Analytics:</strong> We use Google Analytics to help us understand the use of our website. Google Analytics collects information such as how often users visit this site, what pages they visit when they do so, and what other sites they used prior to coming to this site. Google Analytics collects only the IP address assigned to you on the date you visit this site, rather than your name or other identifying information.</p>
        <p><strong>Cookies:</strong> Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site. This cookie cannot be used by anyone but Google, Inc. The information generated by the cookie will be transmitted to and stored by Google on servers in the United States.</p>

        <h3>How We Use Your Information</h3>
        <p>We use the information we collect in various ways, including to:</p>
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h3>How We Share Your Information</h3>
        <p>We do not sell your personal information to third parties. We may share your information as follows:</p>
        <ul>
          <li>As required by law or governmental entity, or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Spartan Innovations LLC, our customers, or others.</li>
          <li>With our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
        </ul>

        <h3>Your Choices</h3>
        <p>You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies. If you want to opt-out of Google Analytics, you can download and install the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a> for your web browser.</p>

        <h3>Changes to This Policy</h3>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions about this privacy policy, please contact us at <a href="mailto:info@spartan-in.com">info@spartan-in.com</a>.</p>
      </div>
    </footer>
  );
};

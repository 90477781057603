import { useEffect } from 'react';

export const useScrollHandler = (tickBoxContentRef) => {
	let initialScrollTop = 0;
	let finalScrollTop = null;

	const handleWheel = (e) => {
		const element = tickBoxContentRef.current;

		if ((e.deltaY < 0 && element.scrollTop === 0) ||
			(e.deltaY > 0 && element.scrollHeight - element.clientHeight === element.scrollTop)) {
			return;
		}
		e.stopPropagation();
	};

	const handleDragStart = (e) => {
		initialScrollTop = tickBoxContentRef.current.scrollTop;
	};

	const handleDragEnd = (e) => {
		finalScrollTop = tickBoxContentRef.current.scrollTop;

		if (initialScrollTop !== finalScrollTop) {
			e.stopPropagation();
		}
	};

	useEffect(() => {
		const element = tickBoxContentRef.current;
		if (element) {
			element.addEventListener('wheel', handleWheel);
			element.addEventListener('touchstart', handleDragStart);
			element.addEventListener('touchend', handleDragEnd);
		}

		return () => {
			if (element) {
				element.removeEventListener('wheel', handleWheel);
				element.removeEventListener('touchstart', handleDragStart);
				element.removeEventListener('touchend', handleDragEnd);
			}
		};
	}, [tickBoxContentRef]);

	return { handleWheel, handleDragStart, handleDragEnd };
};
